import { StyledAppTabs } from '@/app/tabs/styled'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { AppTabsItem } from '@/models/app/types'
import { ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { STORAGE_DARWIN_ERROR, STORAGE_ERROR_PATH_NAME, STORAGE_ERROR_SID } from '@/shared/localStorageUtils'
import { getRouteFromPathname } from '@/shared/utils'
import { clearError } from '@/store/errorHandler/actions'
import {
  clearModuleHeaderActionPanelData,
  clearModuleHeaderConfigData,
  clearModuleHeaderCSVData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

const AppTabs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const tabsDispatch = useAppDispatch()
  const { errorData } = useTypedSelector((state) => ({ ...state }))
  const storedError = useRef<DarwinError>(JSON.parse(localStorage.getItem(STORAGE_DARWIN_ERROR) || '{}') as DarwinError)
  const { sid } = useParams()

  const [tabsItems] = useState<AppTabsItem[]>(() => [
    { label: 'Analysis Backlog', route: ROUTES.ANALYSIS_BACKLOG, isHiddenTab: false },
    { label: 'Open Recommendations', route: ROUTES.UNRESOLVED_RECOMMENDATION, isHiddenTab: false },
    { label: 'Devices & Sensors', route: ROUTES.DEVICES_AND_SENSORS, isHiddenTab: false },
    { label: 'NEW !!!Devices & Sensors!!!', route: ROUTES.NEW_DEVICES_AND_SENSORS, isHiddenTab: false },
    {
      label: 'Automatic Diagnostics',
      route: ROUTES.AUTOMATIC_DIAGNOSTICS,
      isHiddenTab: false,
    },
    { label: 'Dashboard', route: ROUTES.DASHBOARD, isHiddenTab: false },
    {
      label: 'Report Status',
      route: ROUTES.REPORT_STATUS,
      isHiddenTab: true,
      parentRoute: ROUTES.ANALYSIS_BACKLOG,
    },
    { label: 'Overview', route: ROUTES.OVERVIEW, isHiddenTab: true, parentRoute: ROUTES.ANALYSIS_BACKLOG },
    { label: 'Orphan Notes', route: ROUTES.ORPHAN_NOTES, isHiddenTab: true },
    { label: '', route: ROUTES.NOT_FOUND, isHiddenTab: true },
    {
      label: '',
      route: ROUTES.TAB_ERROR,
      isHiddenTab: true,
    },
  ])

  const [selectedTab, setSelectedTab] = useState(-1)

  const [routeName, setRouteName] = useState(() => getRouteFromPathname(pathname))

  useEffect(() => {
    setRouteName(getRouteFromPathname(pathname))
  }, [pathname])

  useDeepCompareEffect(() => {
    let tabIndex = tabsItems.findIndex((item) => item.route === routeName)
    const tabItem = tabsItems[tabIndex]
    if (tabItem && tabItem.parentRoute) {
      tabIndex = tabsItems.findIndex((item) => item.route === tabItem.parentRoute)
      setSelectedTab(tabIndex)
    } else if ((errorData || Object.keys(storedError.current).length > 0) && pathname === ROUTES.TAB_ERROR) {
      const prevUrl = sessionStorage.getItem(STORAGE_ERROR_PATH_NAME)
      if (prevUrl) {
        tabIndex = tabsItems.findIndex((item) => item.route === prevUrl)
      }
      if (tabIndex > -1) {
        setSelectedTab(tabIndex)
      }
    } else {
      setSelectedTab(tabIndex > 0 ? tabIndex : 0)
    }
  }, [tabsItems, errorData, routeName])

  const cleanupModuleHeaderState = () => {
    tabsDispatch(clearModuleHeaderActionPanelData())
    tabsDispatch(clearModuleHeaderConfigData())
    tabsDispatch(clearModuleHeaderCSVData())
  }

  return (
    ([ROUTES.ORPHAN_NOTES, ROUTES.OVERVIEW, ROUTES.ERROR, ROUTES.SITE_DETAILS, ROUTES.REPORT_GENERATOR].includes(
      routeName as ROUTES
    ) && <Outlet />) || (
      <StyledAppTabs
        feSelected={selectedTab}
        feType="expanded"
        feItems={tabsItems
          .filter((item) => !item.isHiddenTab)
          .map((item, index) => ({
            label: item.label,
            children: index === selectedTab && <Outlet />,
          }))}
        onClick={(_, index) => {
          if (index !== selectedTab) {
            cleanupModuleHeaderState()
            setSelectedTab(index)
            tabsDispatch(clearError())
            localStorage.removeItem(STORAGE_DARWIN_ERROR)
            if ([ROUTES.TAB_ERROR, ROUTES.ERROR].includes(routeName as ROUTES)) {
              navigate(`${tabsItems[index].route}/${sessionStorage.getItem(STORAGE_ERROR_SID)}`)
            } else {
              navigate(`${tabsItems[index].route}/${sid}`)
            }
          }
        }}
        data-testid={dataTestId.appTabs}
      />
    )
  )
}

export default AppTabs
