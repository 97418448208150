import { StyledHistoryRowIconContainer } from '../../styled'
import AssetStatusIcon from '@/shared/components/AssetStatusIcon'
import { getWorstStatus } from '@/modules/report-status/utils'
import { AssetHistorySnapshot } from '@/models/reportStatus/types'

export interface AssetStatusProps {
  snapshot: AssetHistorySnapshot | AssetHistorySnapshot[]
}

export const AssetStatus = ({ snapshot }: AssetStatusProps) => {
  const statusValue = !Array.isArray(snapshot) ? getWorstStatus(snapshot) : 'normal'
  return (
    <StyledHistoryRowIconContainer feAlignItems="center">
      <AssetStatusIcon value={statusValue} />
    </StyledHistoryRowIconContainer>
  )
}
