import GenericTableFilter from '@/shared/components/TableComponents/GenericTableFilter'
import { CustomFilterMeta } from '@/shared/components/TableComponents/GenericTableFilter/types'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { Icon } from '@skf-internal/ui-components-react'
import { Column, flexRender, Header } from '@tanstack/react-table'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import TextEllipsis, { TextEllipsisType } from '../../TextEllipsis'
import { getRouteFromPathname } from '@/shared/utils'
import { ROUTES } from '@/shared/constants'
import { useLocation } from 'react-router-dom'
import {
  StyledTabledHeaderColumnIcon,
  StyledTabledHeaderColumnIconContainer,
  StyledTableHeaderColumn,
  StyledTableHeaderColumnContainer,
} from '@/shared/components/TableComponents/styled'
import { MRT_RowData } from 'material-react-table'

interface TableHeaderColumnProps<T> extends PropsWithChildren {
  header: Header<T, unknown>
  columnWidth?: number
  centerValue?: boolean
  spanCol?: number
  withSorting?: boolean
  withContent?: boolean
  title?: string
  characterLength?: number
}

/**
 * TableHeaderColumn component.
 * Renders a table header with optional sorting and filtering features.
 * It also supports custom content and conditional rendering of these features.
 *
 * @template T - The type of the data being used in the table.
 * @param {TableHeaderColumnProps<T>} props - The props object for the component.
 * @returns {JSX.Element} A table header column with optional filtering and sorting.
 *
 * @example
 * <TableHeaderColumn
 *   header={header}
 *   columnWidth={150}
 *   centerValue={true}
 *   spanCol={2}
 *   withSorting={true}
 * >
 *   Custom Content
 * </TableHeaderColumn>
 */

const TableHeaderColumn = <T extends MRT_RowData>({
  header: { column, isPlaceholder, getContext, colSpan },
  columnWidth = 0,
  centerValue,
  spanCol,
  withSorting = true,
  withContent = true,
  children,
  title,
  characterLength,
}: TableHeaderColumnProps<T>) => {
  const HeaderColumnFilter = useCallback((column: Column<T, unknown>) => {
    const columnMeta = column.columnDef?.meta as CustomFilterMeta
    const columnFilterType = columnMeta.filterType
    return <GenericTableFilter<T> column={column} filterType={columnFilterType} />
  }, [])
  const { pathname } = useLocation()
  const width = useMemo(() => (columnWidth ? `${columnWidth}px` : '100%'), [columnWidth])
  const flexContentJustification = useMemo(() => (centerValue ? 'center' : 'start'), [centerValue])
  const canSort = useMemo(() => !!column.getCanSort(), [column])
  const canFilter = useMemo(() => column.getCanFilter() && column.columnDef?.meta, [column])
  const headerComponent = useDeepCompareMemo(
    () => (isPlaceholder ? null : flexRender(column.columnDef.header, getContext())),
    [column.columnDef.header, isPlaceholder]
  )
  const colSpanValue = useMemo(() => (spanCol ? spanCol : colSpan), [colSpan, spanCol])
  const cursor = useMemo(() => (column.getCanSort() ? 'pointer' : 'auto'), [column])

  const isDeviceSensorPath = useMemo(() => {
    const currentRoute = getRouteFromPathname(pathname)
    return currentRoute === ROUTES.DEVICES_AND_SENSORS
  }, [pathname])

  return (
    <StyledTableHeaderColumn
      data-testid={dataTestId.tableGenericHeaderColumn}
      colSpan={colSpanValue}
      width={width}
      cursor={cursor}
      scope="col"
    >
      <StyledTableHeaderColumnContainer flexContentJustification={flexContentJustification}>
        {withContent && (
          <>
            {canFilter && <HeaderColumnFilter data-testid={dataTestId.columnHeaderCustomFilter} {...column} />}
            <StyledTabledHeaderColumnIconContainer onClick={column.getToggleSortingHandler()}>
              {isDeviceSensorPath ? (
                <TextEllipsis value={title} characterLength={characterLength} type={TextEllipsisType.SingleLine} />
              ) : (
                <span data-testid={dataTestId.headerComponent}>{headerComponent}</span>
              )}

              {withSorting &&
                ({
                  asc: <Icon feIcon="caretUp" feSize="sm" />,
                  desc: <Icon feIcon="caretDown" feSize="sm" />,
                }[column.getIsSorted() as string] ??
                  (canSort ? <StyledTabledHeaderColumnIcon feSize="sm" feIcon="caretUpDown" /> : null))}
            </StyledTabledHeaderColumnIconContainer>
          </>
        )}
      </StyledTableHeaderColumnContainer>
      {children}
    </StyledTableHeaderColumn>
  )
}

export default TableHeaderColumn
