import { MainHeader } from '@/app/main-header'
import AppTabs from '@/app/tabs'
import AppDataInitializer from '@/shared/components/AppDataInitializer'
import GenericConfirmationDialog from '@/shared/components/GenericConfirmationDialog'
import GenericToast from '@/shared/components/GenericToast'
import { dataTestId } from '@/tests/testid'
import { ToastProvider } from '@skf-internal/ui-components-react'
import ErrorConsumer from '@/shared/components/ErrorConsumer'
import { useTypedSelector } from '@/store/store'

const ProtectedRoutes = () => {
  const { selectedCustomer } = useTypedSelector((state) => ({ ...state }))

  return (
    <ErrorConsumer>
      <AppDataInitializer>
        {selectedCustomer && <MainHeader />}
        <AppTabs />
        <GenericConfirmationDialog />
        <ToastProvider feErrorTimeout={true} feTimeout={7}>
          <GenericToast />
        </ToastProvider>
        <div data-testid={dataTestId.protectedRoutes} />
      </AppDataInitializer>
    </ErrorConsumer>
  )
}

export default ProtectedRoutes
