import { DeviceInterface, DeviceStatusEnum } from '@/models/devicesAndSensors/types'
import DeviceDataFault from '@/modules/devicesSensors/components/sideMenu/deviceSection/deviceDataFault'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const DeviceStatusIndicator: FC<{ faults: DeviceInterface['faults'] }> = ({ faults }) => {
  return (
    <Flexbox feFlexDirection="column" feGap="xxs">
      {faults.length > 0 ? (
        faults.map((f) => <DeviceDataFault status={DeviceStatusEnum.NotNormal} fault={f} key={f.fault} />)
      ) : (
        <DeviceDataFault status={DeviceStatusEnum.Normal} />
      )}
    </Flexbox>
  )
}

export default DeviceStatusIndicator
