import { dataTestId } from '@/tests/testid'
import { GridItem, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const DataRow: FC<{
  name: string
  value: string | number
}> = ({ name, value }) => {
  return (
    <>
      <GridItem data-testid={dataTestId.deviceSensorsDataRow}>
        <Text>{name}</Text>
      </GridItem>
      <GridItem>
        <Text feFontWeight="bold">{value}</Text>
      </GridItem>
    </>
  )
}

export default DataRow
