import React from 'react'
import { createRoot } from 'react-dom/client'
import 'react-grid-layout/css/styles.css'
import { App } from './app'
import { store } from './store/store'
import { Provider } from 'react-redux'
import ErrorBoundary from '@/shared/components/ErrorBoundary'
import GenericError from '@/shared/components/GenericError'
import { initializeMsal } from '@/modules/azure-auth/initializeMsal'

const initApp = async () => {
  try {
    const msalInstance = await initializeMsal()
    createRoot(document.getElementById('root') as HTMLElement).render(
      <React.StrictMode>
        <Provider store={store}>
          <ErrorBoundary fallbackComponent={<GenericError />}>
            <App authInstance={msalInstance} />
          </ErrorBoundary>
        </Provider>
      </React.StrictMode>
    )
  } catch (error) {
    //TODO REDIRECT TO 500 PAGE
    console.error('Failed to initialize MSAL', error)
  }
}
initApp()
