import { AssetReportFilters } from '@/models/reportGenerator/types'
import { AssetStatus } from '@/shared/models/types'
import { getEnumKeys } from '@/shared/utils'

export const reportSections = {
  SUMMARY_CHARTS: 'summaryCharts',
  SUMMARIZED_ASSET_HEALTH: 'summarizedAssetHealth',
  DETAILED_ASSET_HEALTH: 'detailedAssetHealth',
  LAST_MEASUREMENTS: 'lastMeasurements',
  OPEN_RECOMMENDED_ACTIONS: 'openRecommendedActions',
} as const

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Hide normal', value: 'hideNormal' },
  { label: 'Unsatisfactory, unacceptable, severe', value: 'unsatisfactoryUnacceptableSevere' },
  { label: 'Unsatisfactory, severe', value: 'unsatisfactorySevere' },
  { label: 'Severe', value: 'severe' },
] as const

export const criticalityOptions = [
  { label: 'All', value: 'all' },
  { label: 'A', value: 'a' },
  { label: 'A-B', value: 'ab' },
  { label: 'B-C', value: 'bc' },
] as const

export const assetTypeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Vibration', value: 'vibration' },
  { label: 'AssetType1', value: 'assetType1' },
  { label: 'AssetType2', value: 'assetType2' },
] as const

export const allAssetTypes = assetTypeOptions.map((option) => option.value).filter((value) => value !== 'all')

export const assetCriticalities = ['A', 'B', 'C'] as const

export const initialReportFiltersState: AssetReportFilters = {
  assets: [],
  assetStatus: getEnumKeys(AssetStatus, ['never-reported']),
  assetCriticality: [...assetCriticalities],
  assetType: allAssetTypes,
  reportDate: new Date(),
  reportType: [],
}
