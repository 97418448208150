import { getCustomers } from '@/api/paths/customers'
import { getReportFaultTypes } from '@/api/paths/reportStatus'
import { GetReportFaultTypesResponse } from '@/api/paths/reportStatus/types'
import ReportStatusErrorElement from '@/app/routes/ReportStatusErrorElement'
// import { createDashboard } from '@/api/paths/dashboard'
import ProtectedRoutes from '@/app/routes/ProtectedRoutes'
import { RoutesErrorBoundary } from '@/app/routes/RoutesErrorBoundary'
import { getReportStatusLoaderData } from '@/app/routes/routesUtils'
import { ReportStatusLoaderData } from '@/app/routes/types'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { Customer } from '@/models/customer/types'
import { FaultTypes } from '@/models/reportStatus/types'
import { AnalysisBacklog } from '@/modules/analysis-backlog'
import AutomaticDiagnostics from '@/modules/automatic-diagnostics'
import Dashboard from '@/modules/dashboard'
import { SelectedDashboard } from '@/modules/dashboard/components/SelectedDashboard'
import DevicesSensors from '@/modules/devices-sensors'
import { OrphanNotes } from '@/modules/orphan-notes'
import Overview from '@/modules/overview'
import ReportStatus from '@/modules/report-status'
import ReportStatusDialog from '@/modules/report-status/components/reportStatusDialog'
import SiteDetails from '@/modules/site-details'
import UnresolvedRecommendation from '@/modules/unresolved-recommendation'
import { ErrorNotFound } from '@/shared/components/ErrorNotFound'
import GenericError from '@/shared/components/GenericError'
import { ROUTES } from '@/shared/constants'
import { asyncThrow } from '@/shared/utils'
import { startGlobalLoading } from '@/store/loading/action'
import { store } from '@/store/store'
import { AxiosError } from 'axios'
import { LoaderFunctionArgs, Navigate, RouteObject } from 'react-router-dom'
import ReportGenerator from '@/modules/report-generator'
import DevicesAndSensors from '@/modules/devicesSensors'

const routesConfig = (): RouteObject[] => [
  {
    path: ROUTES.HOME,
    element: <ProtectedRoutes />,
    errorElement: <RoutesErrorBoundary />,
    loader: async (): Promise<Customer[]> => {
      const customersList: Customer[] = (await getCustomers({
        onError: async (error: AxiosError) => {
          asyncThrow(new DarwinError(error))
        },
      })) as Customer[]
      return customersList
    },
    children: [
      {
        path: ROUTES.HOME,
        element: <Navigate to={ROUTES.OVERVIEW} />,
      },
      {
        path: `${ROUTES.ANALYSIS_BACKLOG}/:sid`,
        element: <AnalysisBacklog />,
      },
      {
        path: ROUTES.REPORT_STATUS,
        element: <ReportStatus />,
        children: [
          {
            path: ':assetId',
            element: <ReportStatusDialog />,
            errorElement: <ReportStatusErrorElement />,
            loader: async ({
              params: { assetId },
            }: LoaderFunctionArgs): Promise<void | Partial<ReportStatusLoaderData>> => {
              store.dispatch(startGlobalLoading())
              return getReportStatusLoaderData(assetId)
            },
          },
        ],
      },
      {
        path: `${ROUTES.DASHBOARD}/:sid`,
        element: <Dashboard />,
        loader: async (): Promise<FaultTypes> => {
          let result = [] as FaultTypes
          const faultTypes = (await getReportFaultTypes({
            onError: async (error) => {
              asyncThrow(new DarwinError(error))
            },
          })) as GetReportFaultTypesResponse
          if (faultTypes) {
            result = faultTypes.types.map((type) => {
              return {
                code: type['code'],
                text: type['en'],
              }
            })
          }
          return result
        },
        // action: async ({ request }: ActionFunctionArgs) => {
        //   const form = await request.formData()
        //   const title = form.get('dashboard-title') as string
        //   const newDashboardId = await createDashboard(
        //     { title },
        //     {
        //       onError: async (error) => {
        //         throwError(error)
        //       },
        //     }
        //   )
        //   return newDashboardId
        // },
        children: [
          {
            path: '',
            element: <SelectedDashboard />,
          },
        ],
      },
      {
        path: `${ROUTES.ORPHAN_NOTES}/:sid`,
        element: <OrphanNotes />,
      },
      {
        path: ROUTES.OVERVIEW,
        element: <Overview />,
      },
      {
        path: `${ROUTES.SITE_DETAILS}/:sid`,
        element: <SiteDetails />,
      },
      {
        path: `${ROUTES.DEVICES_AND_SENSORS}/:sid`,
        element: <DevicesSensors />,
      },
      {
        path: `${ROUTES.NEW_DEVICES_AND_SENSORS}/:sid`,
        element: <DevicesAndSensors />,
      },
      {
        path: `${ROUTES.UNRESOLVED_RECOMMENDATION}/:sid`,
        element: <UnresolvedRecommendation />,
        loader: async (): Promise<FaultTypes> => {
          let result = [] as FaultTypes
          const faultTypes = (await getReportFaultTypes({
            onError: async (error) => {
              asyncThrow(new DarwinError(error))
            },
          })) as GetReportFaultTypesResponse
          if (faultTypes) {
            result = faultTypes.types.map((type) => {
              return {
                code: type['code'],
                text: type['en'],
              }
            })
          }
          return result
        },
      },
      {
        path: `${ROUTES.AUTOMATIC_DIAGNOSTICS}/:sid`,
        element: <AutomaticDiagnostics />,
      },
      {
        path: `${ROUTES.REPORT_GENERATOR}/:sid`,
        element: <ReportGenerator />,
      },
      {
        path: ROUTES.TAB_ERROR,
        element: <GenericError />,
      },
      {
        path: ROUTES.ERROR,
        element: <GenericError />,
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <ErrorNotFound />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorNotFound />,
  },
]

export default routesConfig
