import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { StyledDataGrid, StyledDivider } from '@/modules/devices-sensors/components/styled'
import { formatProp } from '@/shared/utils'
import { ChannelTypeText, deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import { roundVoltageValue } from '@/modules/devices-sensors/utils/tableUtils'
import ChannelStatusIndicator from '@/modules/devicesSensors/components/sideMenu/channelSection/channelStatusIndicator'
import DataRow from '@/modules/devicesSensors/components/sideMenu/common/dataRow'
import useGetDataSectionCharactersLength from '@/modules/devicesSensors/hooks/useGetDataSectionCharactersLength'
import { StyledChannelDataWrapper, StyledChannelTitle } from '@/modules/devicesSensors/styled'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Text } from '@skf-internal/ui-components-react'

const ChannelDataSection = () => {
  const {
    devicesAndSensorsSelectedItemsDataNew: { channel: channelData },
  } = useDevicesAndSensorsContext()

  const characterLength = useGetDataSectionCharactersLength()

  if (!channelData) return <></>

  return (
    <>
      <StyledDivider />

      <Flexbox data-testid={dataTestId.channelDataSection} feFlexDirection="column" feGap="md">
        <Flexbox feFlexDirection="column" feGap="xs">
          <Text feFontSize="xl">Channel details</Text>
          <Text feFontSize="lg" feFontWeight="bold">
            {deviceNumberFormatter.format(channelData.number)}. {channelData.name}
          </Text>
          <Text>{ChannelTypeText[channelData.type]}</Text>
        </Flexbox>

        <ChannelStatusIndicator faults={channelData.faults} status={channelData.status} />

        {channelData.status !== 'channelNotConnected' && (
          <>
            <StyledDataGrid>
              <>
                {channelData.type === 'analog' && (
                  <DataRow
                    name="Voltage"
                    value={formatProp(channelData.voltage, (prop) => `${roundVoltageValue(prop)} V`)}
                  />
                )}
              </>
            </StyledDataGrid>
          </>
        )}
        {channelData.uniquePlaces.map((item, index, array) => (
          <StyledChannelDataWrapper key={item.asset.ID} isLastItem={index === array.length - 1}>
            <Flexbox>
              <StyledChannelTitle>Connected Asset:</StyledChannelTitle>
              <Text feFontWeight="bold">
                <TextEllipsis
                  value={formatProp(item.asset, (prop) => prop.name)}
                  type={TextEllipsisType.SingleLine}
                  characterLength={characterLength}
                />
              </Text>
            </Flexbox>

            <Flexbox>
              <StyledChannelTitle>Belongs to:</StyledChannelTitle>
              <Text feFontWeight="bold">
                <TextEllipsis
                  value={formatProp(item, (prop) => prop.functionalLocationName)}
                  type={TextEllipsisType.SingleLine}
                  characterLength={characterLength}
                />
              </Text>
            </Flexbox>
          </StyledChannelDataWrapper>
        ))}
      </Flexbox>
    </>
  )
}

export default ChannelDataSection
