import { FetchDataContextProvider, useFetchDataContext } from '@/contexts/common/fetchDataContext'
import {
  UnresolvedRecommendationsContextProvider,
  useUnresolvedRecommendationsContext,
} from '@/contexts/unresolvedRecommendations'
import { RecommendedAction, UnresolvedRecommendationData } from '@/models/unresolvedRecommendations/types'
import UnresolvedRecommendationsHeader from '@/modules/unresolved-recommendation/components/header'
import RecommendationTable from '@/modules/unresolved-recommendation/components/recommendation-table/table'
import useGetUnresolvedRecommendations from '@/modules/unresolved-recommendation/hooks/useGetUnresolvedRecommendations'
import { unresolvedRecommendationTableDefinition } from '@/modules/unresolved-recommendation/utils/tableUtils'
import DataRefresher from '@/shared/components/DataRefresher'
import useApplyTableFiltersAndSorting from '@/shared/hooks/useApplyTableFiltersAndSorting'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'

import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingType } from '@/shared/hooks/useApi'
import { StyledUnresolvedRecommendationsRoot } from '@/modules/unresolved-recommendation/styled'
import { createCustomSortHandler } from '@/shared/utils'
import { useParams } from 'react-router-dom'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'

const UnresolvedRecommendation: FC = () => {
  return (
    <UnresolvedRecommendationsContextProvider>
      <FetchDataContextProvider>
        <StyledUnresolvedRecommendationsRoot>
          <InnerUnresolvedRecommendation />
        </StyledUnresolvedRecommendationsRoot>
      </FetchDataContextProvider>
    </UnresolvedRecommendationsContextProvider>
  )
}

const InnerUnresolvedRecommendation: FC = () => {
  const [showClosed, setShowClosed] = useState<boolean>(false)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [isTableFiltered, setIsTableFiltered] = useState<boolean>(false)
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'raDaysToDue',
      desc: true,
    },
  ])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { sid } = useParams()
  const { unresolvedRecommendations } = useUnresolvedRecommendationsContext()
  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()
  const { execute, status } = useGetUnresolvedRecommendations()

  const sortingChangeHandler = useMemo(() => createCustomSortHandler(3), [])

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useEffect(() => {
    if (sid) {
      execute({ loaderType: LoadingType.GLOBAL })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useDeepCompareEffect(() => {
    setFetchDataStatus(status)
  }, [status])

  useCleanupStorage()

  const tableData = useMemo(() => {
    if (status !== 'success') return undefined
    const openFormatData = unresolvedRecommendations.open as unknown as RecommendedAction[]
    const closedFormatData = unresolvedRecommendations.closed as unknown as RecommendedAction[]

    return showClosed ? [...openFormatData, ...closedFormatData] : openFormatData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unresolvedRecommendations, showClosed])

  const table = useReactTable({
    data: tableData || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    columns: unresolvedRecommendationTableDefinition(showClosed),
    state: { pagination, sorting, columnFilters },
    onSortingChange: (updater) => {
      setSorting(sortingChangeHandler(updater))
    },
    onColumnFiltersChange: setColumnFilters,
    enableMultiSort: true,
    isMultiSortEvent: () => true,
    onPaginationChange: setPagination,
    maxMultiSortColCount: 3,
  })

  const resetTableFilters = useCallback(() => {
    table.resetColumnFilters()
    table.resetSorting()
    setShowClosed(false)
    setColumnFilters([])
  }, [table])

  const handleClearFilters = () => {
    resetTableFilters()
    setIsTableFiltered(false)
  }

  useEffect(() => {
    setIsTableFiltered(columnFilters.length === 0 && sorting.length === 0)
  }, [columnFilters, sorting])

  useEffect(() => {
    handleClearFilters()
    setSorting([
      {
        id: 'raDaysToDue',
        desc: true,
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  const { tableScrollOffset } = useApplyTableFiltersAndSorting({
    setColumnFilters,
    setIsTableFiltered,
    setSorting,
    setTableState: setShowClosed,
  })

  return (
    <>
      <UnresolvedRecommendationsHeader
        table={table}
        isTableFiltered={isTableFiltered}
        handleClearFilters={handleClearFilters}
        showClosed={showClosed}
        setShowClosed={setShowClosed}
        columnFilters={columnFilters}
      />
      <RecommendationTable table={table} tableScrollOffset={tableScrollOffset} />
      <DataRefresher<UnresolvedRecommendationData> functionToExecute={execute} />
    </>
  )
}

export default UnresolvedRecommendation
