import { COLORS, Flexbox, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledReportGeneratorContainer = styled(Flexbox)`
  overflow: scroll;
  margin-top: -2rem;
  padding-bottom: 2rem;
`

//Asset selection
export const StyledAssetSelectionContainer = styled(Flexbox)`
  width: 20%;
  height: 100vh;
  padding: 0 1rem;
  background: ${COLORS.gray025};
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  align-items: center;
  flex-direction: column;

  @media (width >= 1400px) {
    min-width: 25rem;
  }
`

export const StyledCompanyLogoContainer = styled(Flexbox)`
  height: 10rem;
  justify-content: center;
  align-items: center;
`

export const StyledCompanyLogo = styled.div`
  min-width: 10rem;
  min-height: 7rem;
  padding: 2rem;
`

export const StyledAssetHierarchyContainer = styled(Flexbox)`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: ${COLORS.white};
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  padding: 1.5rem;
  flex-direction: column;
`

//Generate Report
export const StyledGenerateReportContainer = styled.div`
  width: 100%;
`

export const StyledGenerateReportFilterWrap = styled.div`
  padding: 1rem 3rem 0 1rem;
  width: 100%;
  background-color: ${COLORS.gray300};
`

export const StyledGenerateReportFooter = styled.div`
  height: 6rem;
  background: white;
  border-top: 0.1rem solid ${COLORS.gray300};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem 4rem;
`
export const StyledReportFilters = styled(Flexbox)`
  width: 100%;
  height: 75vh;
  flex-direction: column;
  margin-top: 0.5rem;
  background-color: ${COLORS.white};
`

export const StyledReportFiltersContainer = styled(Flexbox)`
  gap: ${SPACINGS.md};
  min-height: 4.69rem;
  background-color: ${COLORS.gray100};
  padding: 2rem;
  flex-direction: column;
`

export const StyledSelectSection = styled(Flexbox)`
  width: 100%;
  display: flex;
  gap: ${SPACINGS.md};
`

export const StyledAssetStatusSelectWrap = styled(Flexbox)`
  display: flex;
  justify-content: center;
  align-items: start;
  min-width: 15rem;
`

export const StyledSelectFiltersSection = styled(Flexbox)`
  width: 70%;
  gap: ${SPACINGS.md};
  display: flex;
  flex-direction: row;

  @media (width >= 1400px) {
    width: 85%;
  }
`

export const StyledReportDetailsSection = styled(Flexbox)`
  width: 30%;
  gap: ${SPACINGS.md};
  display: flex;
  justify-content: center;
  align-items: start;
`

export const StyledFlexItem = styled(Flexbox)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
`
export const StyledReportDateWrap = styled(Flexbox)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;

  label {
    font-weight: 700;
  }
`

export const StyledTotalAssets = styled(Flexbox)`
  width: 25rem;
  font-weight: 700;
  font-size: 0.9rem;
  color: ${COLORS.gray600};
  padding-top: 1.1rem;

  @media (width >= 1400px) {
    font-size: 1.3rem;
    padding-top: 1.5rem;
  }
`

export const StyledIconContainer = styled(Flexbox)`
  min-width: 10rem;
  padding-top: 1.6rem;

  @media (width >= 1400px) {
    gap: 0.25rem;
  }
`

export const StyledReportTypeSection = styled.div`
  width: 100%;
  border-top: 0.12rem solid ${COLORS.gray300};
`

export const StyledReportTypeContainer = styled(Flexbox)`
  font-size: 0.5rem;
  font-weight: 500;
  gap: 2rem;
`

export const StyledReportDateSelectWrap = styled.div`
  label {
    font-weight: 700;
  }

  span {
    font-weight: 400;
  }
`

export const StyledRegenerateButton = styled.button`
  margin-left: auto;
  font-weight: 600;
  color: ${COLORS.gray600};
`
export const StyledReportTypeHeading = styled(Flexbox)`
  padding: 1rem 0;
`

export const StyledReportSelectWrapper = styled.div`
  * {
    flex-wrap: wrap;
  }

  label {
    font-weight: 700;
  }

  span {
    font-weight: 400;
  }

  width: 100%;
`
