import {
  ClosedRaOutcome,
  MappingData,
  RecommendationState,
  RecommendedAction,
} from '@/models/unresolvedRecommendations/types'
import { RedirectButton } from '@/modules/unresolved-recommendation/components/redirectButton/redirectButton'
import { AssetStatusString, MetaTableState } from '@/shared/models/types'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { createColumnHelper } from '@tanstack/react-table'
import { COLORS } from '@skf-internal/ui-components-react'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/TableComponents/sortingFunction'
import { openRecommendationAssetStatusSortingOrder } from '@/shared/components/TableComponents/sortingFunction/constants'
import { convertToDisplayValue, extractDateFromTimestamp } from '@/shared/utils'
import { StyledDaysDueContainer, StyledDaysDueSpan } from '@/modules/unresolved-recommendation/styled'
import { useLoaderData } from 'react-router-dom'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { getMappedValue } from '@/modules/unresolved-recommendation/utils'
import TableCellContent from '@/modules/unresolved-recommendation/components/recommendation-table/table-cell-content'
import AssetStatusIcon from '@/shared/components/AssetStatusIcon'

const columnHelper = createColumnHelper<RecommendedAction>()

function dueInDaysHelper(daysInDue: string) {
  if (!daysInDue) {
    return <StyledDaysDueContainer>{convertToDisplayValue(daysInDue)}</StyledDaysDueContainer>
  }
  const daysDiff = parseInt(daysInDue)
  let backgroundColor = ''
  let color = ''
  if (daysDiff < 1 && daysDiff > -30) {
    backgroundColor = '#BB8EBB'
    color = 'white'
  } else if (daysDiff <= -30) {
    backgroundColor = COLORS.purpleBase
    color = 'white'
  }

  return (
    <StyledDaysDueContainer>
      <StyledDaysDueSpan color={color} backgroundColor={backgroundColor}>
        {daysDiff} Days
      </StyledDaysDueSpan>
    </StyledDaysDueContainer>
  )
}

export const unresolvedRecommendationTableDefinition = (tableState: boolean = false) => [
  columnHelper.accessor((row) => row.assetStatus, {
    id: 'assetStatus',
    header: 'Asset Status',
    cell: ({ getValue, row }) => {
      const assetStatus = getValue()
      if (row.original.raOutcome) {
        return <TableCellContent>{ClosedRaOutcome[row.original.raOutcome]}</TableCellContent>
      } else {
        return <AssetStatusIcon value={assetStatus as AssetStatusString} />
      }
    },
    size: 120,
    enableSorting: true,
    sortingFn: (rowA, rowB, columnId) =>
      sortingRegistry.status<RecommendedAction>(
        rowA,
        rowB,
        columnId,
        createTableSortingOrderMap(openRecommendationAssetStatusSortingOrder)
      ),
    enableColumnFilter: true,
    filterFn: filterRegistry.status.filterFn,
    meta: {
      filterType: 'status',
    },
  }),

  columnHelper.accessor((row) => row.assetName, {
    id: 'assetName',
    header: 'Asset',
    cell: ({ getValue }) => {
      const assetName = getValue<string>()
      return <TableCellContent textAlignCondition={assetName}>{convertToDisplayValue(assetName)}</TableCellContent>
    },
    size: 200,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.assetParent, {
    id: 'assetParent',
    header: 'Belongs To',
    cell: ({ getValue }) => {
      const assetParent = getValue<string>()
      return <TableCellContent textAlignCondition={assetParent}>{convertToDisplayValue(assetParent)}</TableCellContent>
    },
    size: 160,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.faultType, {
    id: 'faultType',
    header: 'Fault',
    cell: ({ getValue }) => {
      // eslint-disable-next-line
      const faultTypes = useLoaderData() as MappingData
      const faultType = getValue<string>()
      return (
        <TableCellContent textAlignCondition={faultType}>
          {convertToDisplayValue(getMappedValue(faultTypes ? faultTypes : [], faultType))}
        </TableCellContent>
      )
    },
    size: 200,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.observation, {
    id: 'observation',
    header: 'Observation',
    cell: ({ getValue }) => {
      const observation = getValue<string>()
      return (
        <TableCellContent textAlignCondition={observation}>
          {
            <TextEllipsis
              type={TextEllipsisType.MultiLine}
              rowsUntilEllipsis={3}
              value={convertToDisplayValue(observation)}
            />
          }
        </TableCellContent>
      )
    },
    size: 400,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.recommendedAction, {
    id: 'recommendedAction',
    header: 'Recommended Action',
    cell: ({ getValue }) => {
      const recommendedAction = getValue<string>()
      return (
        <TableCellContent textAlignCondition={recommendedAction}>
          {
            <TextEllipsis
              type={TextEllipsisType.MultiLine}
              rowsUntilEllipsis={3}
              value={convertToDisplayValue(recommendedAction)}
            />
          }
        </TableCellContent>
      )
    },
    size: 400,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'text',
    },
  }),

  columnHelper.accessor((row) => row.raOpenDate, {
    id: 'raOpenDate',
    header: 'Created',
    cell: ({ getValue }) => {
      const raOpenDate = getValue()
        ? formatDate(extractDateFromTimestamp(getValue()), DateFormats.AmericanDateFormat)
        : RecommendationState.DefaultDate
      return <TableCellContent>{convertToDisplayValue(raOpenDate)}</TableCellContent>
    },
    size: 170,
    sortingFn: sortingRegistry.date<RecommendedAction>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterType: 'date',
    },
    filterFn: filterRegistry.date.filterFn,
  }),

  columnHelper.accessor((row) => row.raDueDate, {
    id: 'raDueDate',
    header: 'Due',
    cell: ({ getValue }) => {
      const raDueDate = getValue()
        ? formatDate(extractDateFromTimestamp(getValue()), DateFormats.AmericanDateFormat)
        : RecommendationState.DefaultDate
      return <TableCellContent>{convertToDisplayValue(raDueDate)}</TableCellContent>
    },
    sortingFn: sortingRegistry.date<RecommendedAction>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterType: 'date',
    },
    filterFn: filterRegistry.date.filterFn,
    size: 145,
  }),

  columnHelper.accessor((row) => (row.raDaysToDue ? row.raDaysToDue.toString() : ''), {
    id: 'raDaysToDue',
    header: 'Days',
    cell: ({ getValue }) => {
      const value = getValue<string>()
      return dueInDaysHelper(value)
    },
    size: 145,
    sortingFn: sortingRegistry.number<RecommendedAction>,
    enableColumnFilter: true,
    filterFn: filterRegistry.text.filterFn,
    meta: {
      filterType: 'number',
    },
  }),

  columnHelper.accessor((row) => row.assetID, {
    id: 'action',
    header: '',
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ getValue, table, column }) => {
      const value = getValue()
      const { columnFilters, sorting } = table.getState()
      const {
        columnDef: { meta },
      } = column
      const tableState = (meta as MetaTableState).tableState

      return <RedirectButton value={value} columnFilters={columnFilters} sorting={sorting} tableState={tableState} />
    },
    size: 50,
    meta: {
      tableState,
    },
  }),
]
