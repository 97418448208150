import dayjs, { Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(minMax)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
})

export enum DateFormats {
  AmericanDateFormat = 'M/D/YYYY',
  AmericanDateTimeFormat = 'M/D/YYYY H:mm:ss A',
  AmericanAMPMFormat = 'M/D/YYYY hh:mm:ss A',
  ISO8601Format = 'ISO8601Format',
  UTCFormat = 'UTCFormat',
  LongDateFormat = 'MMM DD YYYY HH:mm:ss [GMT]Z (zzz)',
  ISO8601Date = 'YYYY-MM-DD',
  MonthNameYearFormat = 'MMMM YYYY',
  MonthShortNameYearFormat = 'MMM YYYY',
}

/** Epoch date value that equals 01/01/1970 00:00:00 */
const epochDate = () => dayjs(0)

/**
 *  Parses the input string dateToParse into a JavaScript Date object using the dayjs library
 *
 * @param {string} dateToParse
 * @return {*} {Date}
 */
const parseDate = (dateToParse: string): Date => dayjs(dateToParse).toDate()

/**
 * Formats the input date using the provided format string. If isUnixTimestamp is true, interprets dateToFormat as a Unix timestamp.
 *
 * @param {(string | Date | number | undefined)} dateToFormat The date to format, which can be a string, a JavaScript Date object, a Unix timestamp, or undefined
 * @param {(string | undefined)} [format=undefined] The format string to apply to the date. Defaults to undefined.
 * @return {*}  {string}
 */
const formatDate = (
  dateToFormat: string | Date | number | undefined,
  format: string | undefined = undefined
): string => {
  const result = dayjs(dateToFormat)

  if (format === DateFormats.ISO8601Format) {
    return result.toISOString()
  } else if (format === DateFormats.UTCFormat) {
    return result.format()
  }

  return result.local().format(format)
}

/**
 * Checks if the input date is the epoch date (1970-01-01T00:00:00.000Z).
 *
 * @param {(string | undefined)} date
 * @return {*}  {boolean}
 */
const isEpochDate = (date: string | undefined): boolean => {
  if (!date) {
    date = dayjs(0).toString()
  }
  return dayjs(date).isSame(epochDate())
}

/**
 * Subtracts the specified value from the input date and returns the result as a string.
 *
 * @param {(string | Date)} date
 * @param {number} value Amount to subtract from the date
 * @param {dayjs.ManipulateType} unit The unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {string}
 */
const subtractDate = (date: string | Date, value: number, unit: dayjs.ManipulateType): string =>
  dayjs(date).subtract(value, unit).toString()

/**
 * Adds the specified value to the input date and returns the result as a string.
 *
 * @param {(string | Date)} date
 * @param {number} value Amount to subtract from the date
 * @param {dayjs.ManipulateType} unit The unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {string}
 */
const addToDate = (date: string | Date, value: number, unit: dayjs.ManipulateType): string =>
  dayjs(date).add(value, unit).toString()

/**
 * Subtracts the specified value to the input date and returns the result.
 *
 * @param {(string | Date)} date
 * @param {number} value Amount to subtract from the date
 * @param {dayjs.ManipulateType} unit The unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {string}
 */
const decrementDate = (date: string | Date, value: number, unit: dayjs.ManipulateType): Date =>
  dayjs(date).subtract(value, unit).toDate()

/**
 * Adds the specified value to the input date and returns the result as a string.
 *
 * @param {(string | Date)} date
 * @param {number} value Amount to subtract from the date
 * @param {dayjs.ManipulateType} unit The unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {string}
 */
const incrementDate = (date: string | Date, value: number, unit: dayjs.ManipulateType): Date =>
  dayjs(date).add(value, unit).toDate()

/**
 * Compares two dates and returns a value indicating their relative order.

 * @param {string | Date} dateA - The first date to compare.
 * @param {string | Date} dateB - The second date to compare.
 * @returns {number} -1 if dateA is before dateB, 1 if dateA is after dateB, or 0 if they are equal.

 * @description
 * This function compares two dates, handling both string and Date object inputs.
 * It utilizes the `dayjs` library for efficient date manipulation and comparison.
 */
const compareDates = (dateA: string | Date, dateB: string | Date, unit: dayjs.ManipulateType = 'd'): number => {
  return dayjs(dateA).diff(dayjs(dateB), unit)
}
/**
 * Finds the maximum date from an array of date strings and returns it as a JavaScript Date object.
 *
 * @param {string[]} array
 * @return {*}  {(Date | undefined)}
 */
const getMaxDateFromArray = (array: string[]): Date | undefined => dayjs.max(array.map((date) => dayjs(date)))?.toDate()

/**
 * Checks if the input date is a valid date.
 *
 * @param {Date} date
 * @return {*}  {boolean}
 */
const isValidDate = (date: Date): boolean => dayjs(date).isValid()

/**
 * Moves the specified part of the date to the start (beginning) of its respective unit and returns the result as a string.
 *
 * @param {(string | Date)} date
 * @param {dayjs.OpUnitType} unit The unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {string}
 */
const moveDatePartToStart = (date: string | Date | null | undefined, unit: dayjs.OpUnitType): string =>
  dayjs(date || new Date())
    .startOf(unit)
    .utc(true)
    .toString()

/**
 * Checks if comparedDate is after compareToDate. Optionally, you can specify the unit for comparison.
 *
 * @param {(string | Date)} comparedDate
 * @param {(string | Date)} compareToDate
 * @param {dayjs.OpUnitType} [unit] Optional unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {boolean}
 */
const isAfter = (comparedDate: string | Date, compareToDate: string | Date, unit?: dayjs.OpUnitType): boolean =>
  dayjs(comparedDate).isAfter(compareToDate, unit)

/**
 * Checks if comparedDate is after compareToDate. Optionally, you can specify the unit for comparison.
 *
 * @param {(string | Date)} comparedDate
 * @param {(string | Date)} compareToDate
 * @param {dayjs.OpUnitType} [unit] Optional unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {boolean}
 */
const isAfterOrSame = (comparedDate: string | Date, compareToDate: string | Date, unit?: dayjs.OpUnitType): boolean =>
  dayjs(comparedDate).isSameOrAfter(compareToDate, unit)

/**
 * Checks if comparedDate is before compareToDate. Optionally, you can specify the unit for comparison.
 *
 * @param {(string | Date)} comparedDate
 * @param {(string | Date)} compareToDate
 * @param {dayjs.OpUnitType} [unit] Optional unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {boolean}
 */
const isBefore = (comparedDate: string | Date, compareToDate: string | Date, unit?: dayjs.OpUnitType): boolean =>
  dayjs(comparedDate).isBefore(compareToDate, unit)

/**
 * Checks if comparedDate is before compareToDate. Optionally, you can specify the unit for comparison.
 *
 * @param {(string | Date)} comparedDate
 * @param {(string | Date)} compareToDate
 * @param {dayjs.OpUnitType} [unit] Optional unit of the value to subtract (e.g.,'minute', 'hour', 'day', 'month', 'year').
 * @return {*}  {boolean}
 */
const isBeforeOrSame = (comparedDate: string | Date, compareToDate: string | Date, unit?: dayjs.OpUnitType): boolean =>
  dayjs(comparedDate).isSameOrBefore(compareToDate, unit)

/**
 * Checks if the input date is in the future relative to the current date.
 *
 * @param {(string | Date)} date
 */
const isFuture = (date: string | Date) => isAfter(date, dayjs().toString(), 'd')

/**
 * Highlights specific dates in a date picker cell.
 *
 * @param {string | number | Dayjs} currentDate - The current date to display in the cell.
 * @param {{ originNode: React.ReactNode; type: string }} info - Information about the cell (e.g., type, origin node).
 * @param {Date[] | Dayjs[] | string[]} datesToHighlight - Dates to highlight in the cell.
 * @returns {React.ReactNode} - The React node to render in the cell.
 */
const highlightDates = (
  currentDate: string | number | Dayjs,
  info: { originNode: React.ReactNode; type: string },
  datesToHighlight: Date[] | Dayjs[] | string[]
) => {
  if (info.type !== 'date') {
    return info.originNode
  }
  if (typeof currentDate === 'string' || typeof currentDate === 'number') {
    return <div className="ant-picker-cell-inner">{currentDate}</div>
  }
  if (
    datesToHighlight
      .map((date) => parseDate(date.toString()))
      .some((highlightDate) => currentDate.isSame(highlightDate))
  ) {
    return (
      <div
        className="ant-picker-cell-inner"
        style={{
          fontWeight: 'bolder',
        }}
      >
        {currentDate.date()}
      </div>
    )
  }
  return <div className="ant-picker-cell-inner">{currentDate.date()}</div>
}

/**
 * Gets a specific part of a date (e.g., year, month, day) using Dayjs.
 *
 * @param {string | Date | Dayjs} date - The date to extract the part from.
 * @param {dayjs.UnitType} unit - The unit of time to retrieve (e.g., 'year', 'month', 'day').
 * @returns {number} - The value of the specified date part.
 */
const getPartOfDate = (date: string | Date | Dayjs, unit: dayjs.UnitType) => {
  return dayjs(date).get(unit)
}

/**
 * Compares two dates to determine if they are equal.
 *
 * @param {string | Date | Dayjs} date1 - The first date to compare.
 * @param {string | Date | Dayjs} date2 - The second date to compare.
 * @returns {boolean} `true` if the dates are equal, `false` otherwise.
 *
 * @description
 * This function takes two dates in various formats (string, Date object, or Dayjs object) and compares them using the `dayjs` library.
 * It calculates the difference between the two dates and returns `true` if the difference is zero, indicating that the dates are equal.
 */
const isDatesEquals = (date1: string | Date | Dayjs, date2: string | Date | Dayjs): boolean => {
  return dayjs(date1).diff(dayjs(date2)) === 0
}

export {
  epochDate,
  parseDate,
  formatDate,
  isEpochDate,
  subtractDate,
  compareDates,
  getMaxDateFromArray,
  isValidDate,
  addToDate,
  moveDatePartToStart,
  isAfter,
  isBefore,
  isFuture,
  highlightDates,
  decrementDate,
  incrementDate,
  getPartOfDate,
  isBeforeOrSame,
  isAfterOrSame,
  isDatesEquals,
}
