import { getUsername } from '@/api/baseClientUtils'
import {
  ClosedFault,
  ClosedRecommendedAction,
  Fault,
  FaultErrorState,
  FaultState,
  FieldErrorState,
  RecommendedAction,
  RecommendedActionErrorState,
  RecommendedActionState,
} from '@/models/reportStatus/faultsFormStateTypes'
import { Optional } from '@/models/reportStatus/types'
import { UPDATE_FAULT, UPDATE_RECOMMENDED_ACTION } from '@/modules/report-status/reducer/actions.types'
import {
  commonFaultErrorStruct,
  commonRecommendedActionErrorStruct,
  ErrorTexts,
} from '@/modules/report-status/utils/constants'
import { DateFormats, formatDate, isAfter, isBefore, moveDatePartToStart, parseDate } from '@/shared/dateUtils'
import { isObject } from '@/shared/utils'
import { Dispatch } from 'react'

/**
 * Gets the last collected date.
 *
 * @param {string | undefined} date - The date string to be formatted.
 * @returns {string} The formatted last collected date in ISO 8601 format.
 *
 * @description
 * This function takes a date string or undefined value and returns the formatted last collected date.
 * If the input date is either 'no date available' or undefined, it returns the current date formatted in ISO 8601 format.
 * Otherwise, it formats the provided date string into ISO 8601 format.
 */
const getLastCollectedDate = (date: string | undefined | null) => {
  if (date === 'no date available' || !date) {
    return formatDate(new Date(), DateFormats.ISO8601Format)
  }
  return formatDate(date, DateFormats.ISO8601Format)
}

/**
 * Extracts the entity ID from a fault or recommended action object.
 *
 * @param {Fault | RecommendedAction} fault - The fault or recommended action object.
 * @returns {string} The extracted entity ID or an empty string if not found.
 *
 * @description
 * This function attempts to retrieve the entity ID from a provided `fault` or `recommendedAction` object.
 * It checks if the object has an `id` property and returns it if present.
 * Otherwise, it returns an empty string.
 *
 */
const getEntityId = (entity: Fault | RecommendedAction): string => {
  if ('id' in entity) {
    return entity.id
  }
  return ''
}

/**
 * Transforms a recommended action to a closed recommended action.
 *
 * @param {RecommendedAction} recommendedAction - The recommended action object to be transformed.
 * @returns {Promise<RecommendedAction | Optional<ClosedRecommendedAction, 'outcome'>>} -
 * A promise that resolves to either:
 *   - The original `recommendedAction` object if its state is not 'open'.
 *   - A new `ClosedRecommendedAction` object with additional properties if the state is 'open'.
 *
 * @throws {Error} - Throws an error if the recommended action state is 'new'. This state cannot be transformed to 'closed'.
 *
 * @description
 * This function asynchronously transforms a provided `recommendedAction` object to a
 * `ClosedRecommendedAction` object. It checks the state of the `recommendedAction`:
 *   - If the state is 'new', it throws an error as a new recommended action cannot be closed.
 *   - If the state is 'open', it creates a new object with the following properties:
 *     - `id`: The ID of the original recommended action.
 *     - `recommendedAction`: The recommended action text from the original object.
 *     - `additionalNotes`: Any additional notes associated with the original object.
 *     - `workOrder`: The work order associated with the original object (if present).
 *     - `dueDate`: The due date of the original recommended action (if present).
 *     - `createdDate`: The created date of the original recommended action.
 *     - `state`: The new state set to `RecommendedActionState.PENDING_CLOSE`.
 *     - `closeDate`: The current date formatted in ISO 8601 format using the `formatDate` function.
 *     - `username`: The username of the user performing the closure (assumed to be retrieved asynchronously).
 *   - If the state is anything else, the function simply returns the original `recommendedAction` object.
 *
 */
const transformRecommendedActionToClosedRecommendedAction = async (
  recommendedAction: RecommendedAction
): Promise<RecommendedAction | Optional<ClosedRecommendedAction, 'outcome'>> => {
  switch (recommendedAction.state) {
    case 'new':
      throw new Error('cannot transform new recommended action to closed recommended action')
    case 'open':
      return {
        id: recommendedAction.id,
        recommendedAction: recommendedAction.recommendedAction,
        additionalNotes: recommendedAction.additionalNotes,
        workOrder: recommendedAction.workOrder,
        dueDate: recommendedAction.dueDate,
        createdDate: recommendedAction.createdDate,
        state: RecommendedActionState.PENDING_CLOSE,
        closeDate: formatDate(new Date(), DateFormats.ISO8601Format),
        username: await getUsername(),
      }
    default:
      return recommendedAction
  }
}

/**
 * Updates an object or array recursively.

 * @template T The type of the object or array to be updated.
 * @param {T} original - The original object or array.
 * @param {T} updated - The updated object or array.
 * @returns {{ updated: T; hasChanges: boolean }} An object containing the updated object or array and a boolean indicating whether changes were made.

 * @description
 * This function recursively updates an object or array, returning the updated object and a flag indicating whether any changes were made.

 * **For arrays:**
 * - It iterates over both arrays and compares corresponding elements.
 * - If an element in the `updated` array is different from the corresponding element in the `original` array, it updates the element in the `updated` array.
 * - It returns the updated array and a flag indicating whether any changes were made.

 * **For objects:**
 * - It recursively iterates over the properties of both objects and updates the properties in the `updated` object with values from the `original` object if they are different.
 * - It returns the updated object and a flag indicating whether any changes were made.

 * **For primitive types:**
 * - It simply returns the `updated` value and a flag indicating whether the `updated` value is different from the `original` value.
 */
const updateEntity = <T>(original: T, updated: T): { updated: T; hasChanges: boolean } => {
  if (Array.isArray(original) && Array.isArray(updated)) {
    const { updated: updatedArray, hasChanges } = updateArray(original, updated)
    return { updated: updatedArray as T, hasChanges }
  }

  if (typeof original === 'object' && typeof updated === 'object' && original !== null && updated !== null) {
    return updateObject(original, updated)
  }

  // Fallback for primitive types
  return { updated, hasChanges: original !== updated }
}

/**
 * Updates an array of objects recursively based on identifier properties.

 * @template T - The type of object in the array, requiring `id` or `uniqueId` property.
 * @param {T[]} original - The original array of objects.
 * @param {T[]} updated - The updated array of objects.
 * @returns {{ updated: T[]; hasChanges: boolean }} An object containing the updated array and a boolean indicating whether changes were made.

 * @description
 * This function recursively updates an array of objects. It assumes that each object
 * in the array has either an `id` or a `uniqueId` property for identification.

 * **Process:**
 * 1. Initializes a `hasChanges` flag to `false`.
 * 2. Iterates over the original array using `map`.
   - For each item in the original array:
     - Attempts to find a corresponding item in the `updated` array based on the `id` or `uniqueId` property.
     - If the item is a plain object (not an array) and has an `id` or `uniqueId`:
       - Uses `find` to locate the matching item in the `updated` array.
     - If the item is a primitive type (string, number, etc.):
       - Uses `indexOf` to find the index of the item in both arrays.
       - If the index is the same in both arrays, keeps the original item.
       - If the index is different in the arrays, uses the corresponding item from the `updated` array.
     - If no matching item is found in the `updated` array, keeps the original item.
     - If the updated item is an object or array, performs a recursive update using `updateEntity`.
     - If the updated item is a primitive type and differs from the original, sets `hasChanges` to `true`.

 * 3. Handles cases where the `updated` array has more elements than the original:
   - Appends the extra elements from the `updated` array to the end of the `resultArray`.
   - Sets `hasChanges` to `true`.

 * 4. Returns an object containing the updated array and the `hasChanges` flag.
*/

const updateArray = <T extends { id: string; uniqueId: string }>(
  original: T[],
  updated: T[]
): { updated: T[]; hasChanges: boolean } => {
  let hasChanges = false

  // Iterate over original
  const resultArray = original.map((item) => {
    let updatedItem = undefined

    if (isObject(item)) {
      if ('id' in item) {
        updatedItem = updated.find((itemToUpdate) => itemToUpdate.id === item.id)
      }
      if ('uniqueId' in item) {
        updatedItem = updated.find((itemToUpdate) => itemToUpdate.uniqueId === item.uniqueId)
      }
    } else {
      const updatedIndexNumber = updated.indexOf(item)
      const originalIndexNumber = original.indexOf(item)
      updatedItem = updatedIndexNumber === -1 ? updated[originalIndexNumber] : item
    }

    //if no matching item found, keep the original
    if (updatedItem === undefined) {
      return item
    }

    // If the item is an object or array, recurse into it
    if (isObject(updatedItem) && updatedItem !== null) {
      const { updated: nestedUpdated, hasChanges: nestedHasChanges } = updateEntity(item, updatedItem)
      if (nestedHasChanges) hasChanges = true
      return nestedUpdated
    }

    // Direct comparison for primitive values
    if (updatedItem !== item) {
      hasChanges = true
      return updatedItem
    }

    return item // Keep original if no changes
  })

  // Handle cases where the updated array is longer than the original
  if (updated.length > original.length) {
    hasChanges = true
    resultArray.push(...updated.slice(original.length)) // Append the extra items
  }

  return { updated: resultArray, hasChanges }
}

/**
 * Updates an object recursively, comparing properties and merging changes.

 * @template T - The type of the object to be updated.
 * @param {T} original - The original object.
 * @param {T} updated - The updated object with potential changes.
 * @returns {{ updated: T; hasChanges: boolean }} An object containing the updated object and a boolean indicating whether changes were made.

 * @description
 * This function updates an object recursively, comparing properties with a corresponding object and merging changes. It excludes files from the update process.

 * **Process:**
 * 1. Initializes a `hasChanges` flag to `false`.
 * 2. Checks if the `original` object is a `File` object.
   - If it is, returns the original object and sets `hasChanges` to `false` (files are considered immutable).
 * 3. Creates a shallow copy of the `original` object using the spread operator (`...`).
 * 4. Iterates over the enumerable properties of the `updated` object.
   - For each property in the `updated` object:
     - Checks if the property exists using `hasOwnProperty`.
     - Extracts the original and updated values for the current property.
     - If both values are arrays:
       - Calls the `updateArray` function recursively to update the nested array.
       - Updates the property in the result with the updated array and sets `hasChanges` based on the nested update result.
     - If both values are objects (not null):
       - Calls the `updateEntity` function recursively to update the nested object.
       - Updates the property in the result with the updated object and sets `hasChanges` based on the nested update result.
     - If the values are primitive types and differ, updates the property in the result and sets `hasChanges` to `true`.

 * 5. Returns an object containing the updated object and the `hasChanges` flag.
*/
const updateObject = <T>(original: T, updated: T): { updated: T; hasChanges: boolean } => {
  let hasChanges = false
  if (original instanceof File) {
    return { updated: original, hasChanges: false }
  }
  const result = { ...original }

  // Iterate over keys of the updated object
  for (const key in updated) {
    if (Object.prototype.hasOwnProperty.call(updated, key)) {
      const originalValue = (original as T)[key]
      const updatedValue = (updated as T)[key]

      // If both are arrays, recurse into the array update function
      if (Array.isArray(originalValue) && Array.isArray(updatedValue)) {
        const { updated: nestedUpdated, hasChanges: nestedHasChanges } = updateArray(originalValue, updatedValue)
        result[key] = nestedUpdated as typeof originalValue
        if (nestedHasChanges) hasChanges = true
      }
      // If both are objects, recurse into the object update function
      else if (isObject(originalValue) && isObject(updatedValue) && originalValue !== null && updatedValue !== null) {
        const { updated: nestedUpdated, hasChanges: nestedHasChanges } = updateEntity(originalValue, updatedValue)
        result[key] = nestedUpdated
        if (nestedHasChanges) hasChanges = true
      }
      // Otherwise, compare primitive values directly
      else if (originalValue !== updatedValue) {
        result[key] = updatedValue
        hasChanges = true
      }
    }
  }

  return { updated: result as T, hasChanges }
}

/**
 * Handles component change events and dispatches appropriate Redux actions.

 * @template T - The type of the new value.
 * @param {T} newValue - The new value to be set.
 * @param {Dispatch<unknown>} faultsFormDispatch - The Redux dispatch function.
 * @param {string} fieldId - The ID of the field being updated.
 * @param {string} fieldName - The name of the field being updated.
 * @param {string | undefined} parentFieldId - The optional ID of the parent field (if applicable).

 * @description
 * This function dispatches a Redux action to update either a `fault` or a `recommendedAction`, depending on the presence of the `parentFieldId`.

 * **If `parentFieldId` is provided:**
 * - Dispatches an `UPDATE_RECOMMENDED_ACTION` action with the following payload:
   - `id`: The ID of the recommended action being updated.
   - `fieldName`: The name of the field being updated.
   - `newValue`: The new value for the field.
   - `faultId`: The ID of the parent fault.

 * **If `parentFieldId` is not provided:**
 * - Dispatches an `UPDATE_FAULT` action with the following payload:
   - `id`: The ID of the fault being updated.
   - `fieldName`: The name of the field being updated.
   - `newValue`: The new value for the field.
 */
const onComponentChange = <T>(
  newValue: T,
  faultsFormDispatch: Dispatch<unknown>,
  fieldId: string,
  fieldName: string,
  parentFieldId?: string | undefined
) => {
  if (parentFieldId) {
    faultsFormDispatch({
      type: UPDATE_RECOMMENDED_ACTION,
      payload: {
        id: fieldId,
        [fieldName]: newValue,
        faultId: parentFieldId,
      },
    })
  } else {
    faultsFormDispatch({
      type: UPDATE_FAULT,
      payload: {
        id: fieldId,
        [fieldName]: newValue,
      },
    })
  }
}

/**
 * Builds a structured error object based on an array of faults.

 * @param {Fault[]} faults - An array of fault objects containing error details.
 * @returns {FaultErrorState[]} An array of error objects with structured information.

 * @description
 * This function processes an array of `Fault` objects and transforms them into a structured error object. It filters out closed recommended actions and creates corresponding error objects for each remaining recommended action.

 * **Process:**
 * 1. Initializes an empty array (`faultAcc`) to accumulate error objects.
 * 2. Uses `reduce` to iterate over the `faults` array.
   - For each `fault`:
     - Creates a deep copy of `commonFaultErrorStruct` using `structuredClone`.
     - Sets the `id` property of the error object using `getEntityId` (assumed to be a function that retrieves the entity ID from the fault).
     - Filters the `recommendedActions` array from the fault to exclude those with state `RecommendedActionState.CLOSED`.
     - Uses a nested `reduce` to iterate over the filtered `recommendedActions`:
       - For each `recommendedAction`:
         - Creates a deep copy of `commonRecommendedActionErrorStruct` using `structuredClone`.
         - Sets the `id` property of the error recommended action using `getEntityId`.
         - Pushes the error recommended action object to the `raAcc` accumulator.
     - Updates the error object's `recommendedActions` property with the filtered and transformed recommended actions.
     - Pushes the completed error object containing fault and recommended action information to the `faultAcc` accumulator.

 * 3. Returns the final array of `FaultErrorState` objects containing the structured error information.
*/
const buildFormErrorStruct = (faults: Fault[]) => {
  return faults.reduce((faultAcc, current) => {
    const errorFault = structuredClone(commonFaultErrorStruct)
    errorFault.id = getEntityId(current)
    errorFault.recommendedActions = current.recommendedActions
      .filter((ra) => ra.state !== RecommendedActionState.CLOSED)
      .reduce((raAcc, raCurrent) => {
        const errorRecommendedAction = structuredClone(commonRecommendedActionErrorStruct)
        errorRecommendedAction.id = getEntityId(raCurrent)
        raAcc.push(errorRecommendedAction)
        return raAcc
      }, [] as RecommendedActionErrorState[])
    faultAcc.push(errorFault)
    return faultAcc
  }, [] as FaultErrorState[])
}

/**
 * Sets an error state for a field.

 * @param {FieldErrorState} errorField - The error state object to be updated.

 * @description
 * This function updates the `error` and `isValid` properties of the provided `errorField` object to indicate a required field error.
 * The `error` property is set to a predefined error message `ErrorTexts.REQUIRED`, and the `isValid` property is set to `false`.
 */
const setRequiredError = (errorField: FieldErrorState) => {
  errorField.error = ErrorTexts.REQUIRED
  errorField.isValid = false
}

/**
 * Clears an error state for a field.

 * @param {FieldErrorState} errorField - The error state object to be cleared.

 * @description
 * This function clears the error state of a field by setting the `error` property to an empty string and the `isValid` property to `true`.
 */
const clearError = (errorField: FieldErrorState) => {
  errorField.error = ''
  errorField.isValid = true
}

/**
 * Sets a custom error state for a field.

 * @param {FieldErrorState} errorField - The error state object to be updated.
 * @param {string} customError - The custom error message to be set.

 * @description
 * This function updates the `error` and `isValid` properties of the provided `errorField` object with a custom error message.
 * The `error` property is set to the specified `customError` message, and the `isValid` property is set to `false`.
 */
const setCustomError = (errorField: FieldErrorState, customError: string) => {
  errorField.error = customError
  errorField.isValid = false
}
/**
 * Updates the error state of a field based on the provided value and custom error message.

 * @param {FieldErrorState} errorField - The error state object to be updated.
 * @param {string | number | undefined | null} value - The value of the field.
 * @param {string | undefined} customError - An optional custom error message.

 * @description
 * This function updates the `error` and `isValid` properties of the `errorField` object based on the following conditions:
   - If the `value` is truthy (not null, undefined, empty string, or 0), the error is cleared.
   - If a `customError` is provided, it's set as the error message.
   - Otherwise, a required field error is set.
 */
const updateErrorState = (
  errorField: FieldErrorState,
  value: string | number | undefined | null,
  customError?: string
) =>
  value?.toString().trim()
    ? clearError(errorField)
    : customError
      ? setCustomError(errorField, customError)
      : setRequiredError(errorField)

/**
 * Validates date fields in a fault error state and recommended actions.

 * @param {FieldErrorState} faultErrorState - The error state object for the fault.
 * @param {string} collectionDate - The collection date of the fault (string format).
 * @param {RecommendedAction[]} recommendedActions - An array of recommended actions associated with the fault.

 * @description
 * This function validates the collection date and due dates of recommended actions within a fault error state. It performs the following actions:
   - Parses the collection date and caches the parsed value for efficiency.
   - Updates the error state of the collection date field.
   - Iterates over recommended actions:
     - Finds the corresponding error state object for each action.
     - Parses the due date of the recommended action (cached if parsed before).
     - Updates the error state of the recommended action due date field (if the state object exists).
     - Checks if the recommended action due date is before the collection date, setting an error message if true.
   - Checks if any recommended action due date is before the collection date.
   - If any due date error is found, sets an error message for the collection date field.
 */
const validateFormDates = (
  faultErrorState: FaultErrorState,
  collectionDate: string,
  recommendedActions: RecommendedAction[]
) => {
  // Cache parsed collection date once to avoid repetitive parsing.
  const parsedCollectionDate = parseDate(moveDatePartToStart(collectionDate, 'd'))

  // Update the error state of the collection date with the current value.
  updateErrorState(faultErrorState.collectionDate, collectionDate)

  // Flag to indicate if any recommended action has a due date error relative to the collection date.
  let hasDueDateError = false

  // Iterate over each recommended action to update states and validate date relationships.
  recommendedActions.forEach((ra) => {
    // Find the corresponding error state object for the current recommended action.
    const raStateToUpdate = faultErrorState.recommendedActions.find((fra) => fra.id === getEntityId(ra))

    // Parse the due date of the recommended action once.
    const parsedDueDate = parseDate(moveDatePartToStart(ra.dueDate, 'd'))

    // Update the error state of the recommended action due date if the state exists.
    if (raStateToUpdate) {
      updateErrorState(raStateToUpdate.dueDate, ra.dueDate)

      // Check if the recommended action's due date is before the collection date.
      if (isBefore(parsedDueDate, parsedCollectionDate)) {
        updateErrorState(raStateToUpdate.dueDate, '', 'Recommended action due date must be after fault collection date')
      }
    }

    // Check if the collection date is after any recommended action due date.
    if (isAfter(parsedCollectionDate, parsedDueDate)) {
      hasDueDateError = true
    }
  })

  // If any due date is found to be earlier than the collection date, update the error state.
  if (hasDueDateError) {
    updateErrorState(faultErrorState.collectionDate, '', 'Collection date should not be greater than due dates')
  }
}

/**
 * Validates a fault object and updates an existing error state.

 * @param {Fault} updatedFault - The updated fault object to be validated.
 * @param {FieldErrorState[]} currentErrorState - The current error state for the fault (optional).
 * @param {boolean} withRecommendedActionsValidation - Flag indicating whether to validate recommended actions (defaults to `false`).

 * @returns {FieldErrorState[]} Updated error state array or undefined (if no errors found).

 * @description
 * This function validates a `Fault` object and updates the corresponding error state within a provided error state array. 

 * **Process:**
 * 1. Checks if `currentErrorState` exists. If not, returns `undefined`.
 * 2. Creates a copy of the `currentErrorState` using `updateEntity` (assumed to be a function that performs a deep copy).
 *   - If `withRecommendedActionsValidation` is true:
     - Creates a new error state for the updated fault using `buildFormErrorStruct`.
     - Updates the existing error state with the newly built error state using `updateEntity`.
 * 3. Finds the existing error state for the updated fault based on its ID using `getEntityId`.
 * 4. Handles two scenarios:
   - **Adding a new Fault:**
     - If no existing error state is found, builds a new error state using `buildFormErrorStruct` and populates required error messages for all fields (fault, status, observation, recommended action, recommended action outcome).
     - Returns a merged array containing the existing error state and the newly built error state.
   - **Updating an existing Fault:**
     - Extracts properties from the updated fault object using type assertion (`updatedFault as ClosedFault`).
     - Calls `updateErrorState` to validate and update individual fields (fault, status, observation, explanation, recommended action outcome) based on their values and conditions.
     - Calls a separate function `validateFormDates` (assumed to handle date validation logic).
     - If `state` is `FaultState.PENDING_CLOSE`:
       - Validates recommended action outcomes based on existing error state and updated recommended actions.
     - If `withRecommendedActionsValidation` is true, calls a separate function `validateRecommendedAction` for each recommended action (assumed to perform more specific validation).
 * 5. Returns the updated error state array.
 */
const validateFault = (
  updatedFault: Fault,
  currentErrorState: FaultErrorState[] | undefined,
  withRecommendedActionsValidation: boolean = false
): FaultErrorState[] | undefined => {
  if (!currentErrorState) return undefined

  let errorState = currentErrorState

  if (withRecommendedActionsValidation) {
    const newErrorState = buildFormErrorStruct([updatedFault])
    const updatedErrorStateData = updateEntity(currentErrorState, newErrorState)
    errorState = updatedErrorStateData.updated
  }

  const faultErrorStateToUpdate = errorState.find((errorState) => errorState.id === getEntityId(updatedFault))

  //Add new Fault
  if (!faultErrorStateToUpdate) {
    const newErrorState = buildFormErrorStruct([updatedFault]).map((errorData) => {
      setRequiredError(errorData.fault)
      setRequiredError(errorData.status)
      setRequiredError(errorData.observation)

      errorData.recommendedActions = errorData.recommendedActions.map((ra) => {
        setRequiredError(ra.recommendedAction)
        return ra
      })
      return errorData
    })

    return [...errorState, ...newErrorState]
  }
  //Update existing fault (edit, close, or new that its values were changed)
  else {
    const { fault, status, collectionDate, observation, correctDiagnostic, explanation, state, recommendedActions } =
      updatedFault as ClosedFault

    updateErrorState(faultErrorStateToUpdate.fault, fault)
    updateErrorState(faultErrorStateToUpdate.status, status)
    updateErrorState(faultErrorStateToUpdate.observation, observation)

    if (state.toString() === FaultState.PENDING_CLOSE && !correctDiagnostic) {
      updateErrorState(faultErrorStateToUpdate.explanation, explanation)
    }

    validateFormDates(faultErrorStateToUpdate, collectionDate, recommendedActions)

    if (state.toString() === FaultState.PENDING_CLOSE) {
      faultErrorStateToUpdate.recommendedActions.map((ra) => {
        const recommendedAction = recommendedActions.find((item) => getEntityId(item) === ra.id)

        updateErrorState(ra.outcome, recommendedAction?.outcome)
      })
    }
    if (withRecommendedActionsValidation) {
      recommendedActions.forEach((ra) => validateRecommendedAction(ra, updatedFault, errorState))
    }
  }

  return errorState
}

/**
 * Validates a recommended action object and updates its error state within an existing fault error state.

 * @param {RecommendedAction} updatedRecommendedAction - The updated recommended action object to be validated.
 * @param {Fault} recommendedActionFault - The fault object associated with the recommended action.
 * @param {FieldErrorState[]} currentErrorState - The current error state for the fault (optional).

 * @returns {FieldErrorState[]} Updated error state array or undefined (if no errors found).

 * @description
 * This function validates a `RecommendedAction` object and updates its corresponding error state within the error state for the associated `Fault`.

 * **Process:**
 * 1. Checks if `currentErrorState` exists. If not, returns `undefined`.
 * 2. Finds the error state object for the `recommendedActionFault` using `getEntityId`.
 * 3. If the error state object is found, further finds the recommended action state object within it based on the ID of the `updatedRecommendedAction`.
 * 4. Handles the case where no error state objects are found: returns `undefined`.
 * 5. Extracts properties from the `updatedRecommendedAction` object using type assertion (`updatedRecommendedAction as ClosedRecommendedAction`).
 * 6. Calls `updateErrorState` to validate and update individual fields (recommended action, outcome). 
 * 7. If `state` is `RecommendedActionState.PENDING_CLOSE`:
   - Validates the recommended action outcome.
   - Clears any existing error on the `additionalNotes` field.
 * 8. Calls `validateFormDates` with the fault collection date and the updated recommended action array.
 * 9. If `state` is `RecommendedActionState.PENDING_CLOSE`, `outcome` is `rejected`, and there are no `additionalNotes`, sets an error message for the `additionalNotes` field.
 * 10. Returns the original error state array (no modification to the error state structure itself).
 */
const validateRecommendedAction = (
  updatedRecommendedAction: RecommendedAction,
  recommendedActionFault: Fault,
  currentErrorState: FaultErrorState[] | undefined
): FaultErrorState[] | undefined => {
  if (!currentErrorState) return undefined
  const faultErrorStateToUpdate = currentErrorState.find(
    (errorState) => errorState.id === getEntityId(recommendedActionFault)
  )
  const recommendedActionStateToUpdate = faultErrorStateToUpdate?.recommendedActions.find(
    (ra) => ra.id === getEntityId(updatedRecommendedAction)
  )

  if (!recommendedActionStateToUpdate || !faultErrorStateToUpdate) return undefined

  const { recommendedAction, state, outcome, additionalNotes } = updatedRecommendedAction as ClosedRecommendedAction

  updateErrorState(recommendedActionStateToUpdate.recommendedAction, recommendedAction)

  if (state.toString() === RecommendedActionState.PENDING_CLOSE) {
    updateErrorState(recommendedActionStateToUpdate.outcome, outcome)
    clearError(recommendedActionStateToUpdate.additionalNotes)
  }

  validateFormDates(faultErrorStateToUpdate, recommendedActionFault.collectionDate, [updatedRecommendedAction])

  if (state.toString() === RecommendedActionState.PENDING_CLOSE && outcome === 'rejected' && !additionalNotes) {
    updateErrorState(
      recommendedActionStateToUpdate.additionalNotes,
      '',
      'Additional comments are required for rejected recommended actions'
    )
  }

  return currentErrorState
}

/**
 * Revalidates an array of faults and updates their error states.

 * @param {Fault[]} faults - An array of fault objects to be validated.
 * @param {FieldErrorState[]} currentErrorState - The current error state array.
 * @returns {FieldErrorState[]} The updated error state array.

 * @description
 * This function revalidates an array of `Fault` objects and updates the corresponding error states. It iterates over each fault, validates it using the `validateFault` function, and merges the resulting error states.

 * **Process:**
 * 1. Iterates over the `faults` array.
 * 2. For each fault, calls the `validateFault` function with `withRecommendedActionsValidation` set to `true`.
 * 3. Uses `flatMap` to flatten the resulting error states into a single array, filtering out any `undefined` values.
 * 4. Returns the updated error state array.
 * 
 *  *!!!!DO NOT RUN THIS FUNCTION IF IT IS NOT REALLY NECESSARY. NOW IN USE ONLY ON FORM WORK RESET
 * 
 *   :)))PROCEED WITH CAUTION! MAY THE CODING GODS BE WITH YOU!((((:
 */
const revalidateFaultFormErrors = (faults: Fault[], currentErrorState: FaultErrorState[]): FaultErrorState[] => {
  // Iterate over faults and validate each, filtering out undefined results.
  return faults
    .flatMap((fault) => validateFault(fault, currentErrorState, true) || []) // Use flatMap to merge results and handle undefined.
    .filter((errorState) => errorState !== undefined) // Explicitly filter out any undefined values (optional for added type safety).
}

/**
 * Recursively checks if an object or array is valid.

 * @param {unknown} obj - The object or array to be validated.
 * @returns {boolean} `true` if the object or array is valid, `false` otherwise.

 * @description
 * This function recursively validates objects and arrays to ensure they meet certain criteria.

 * **For arrays:**
 * - It iterates over each element in the array and recursively checks its validity.
 * - If any element is invalid, the entire array is considered invalid.

 * **For objects:**
 * - It checks if the object has an `isValid` property set to `false`. If so, the object is considered invalid.
 * - It recursively checks the values of all properties in the object.
 * - If any nested property is invalid, the entire object is considered invalid.

 * **For other values:**
 * - Any value that is not an object or array is considered valid.
*/
const isFaultFormValid = (obj: unknown): boolean => {
  if (Array.isArray(obj)) {
    // If the input is an array, recursively check each item in the array
    return obj.every((item) => isFaultFormValid(item))
  } else if (obj && typeof obj === 'object') {
    // Check if the object has an `isValid` property and it's false
    if ('isValid' in obj && obj.isValid === false) {
      return false
    }

    // Recursively check all values of the object to find invalid fields
    return Object.values(obj).every((value) => isFaultFormValid(value))
  }

  // Return true if it's not an object, array, or a valid `FieldErrorState`
  return true
}

/**
 * Removes specified keys from an object or array recursively.

 * @template T - The type of the object or array to be processed.
 * @param {T} obj - The object or array from which to remove keys.
 * @param {string[]} excludeKeys - An array of keys to be excluded.
 * @returns {T} A new object or array with the excluded keys removed, or the original value if not an object or array.

 * @description
 * This function removes specified keys from an object or array and its nested structures recursively.

 * **Process:**
 * 1. Checks if `obj` is an array:
   - Iterates through each element in the array and recursively removes the excluded keys.
   - Returns a new array with the processed elements (cast back to type `T`).
 * 2. Checks if `obj` is an object (excluding null):
   - Creates a new empty object (`newObj`).
   - Iterates through each key in `obj`.
     - Checks if the key exists on the object using `hasOwnProperty` and is not in the `excludeKeys` list.
     - Recursively removes the excluded keys from the object's value.
     - Adds the filtered key-value pair to the `newObj`.
   - Returns the `newObj` cast back to type `T`.
 * 3. If `obj` is neither an object nor an array, returns the original value.
*/
const removeKeysRecursively = <T>(obj: T, excludeKeys: string[]): T => {
  if (Array.isArray(obj)) {
    // Recursively process each item in the array
    return obj.map((item) => removeKeysRecursively(item, excludeKeys)) as T
  } else if (typeof obj === 'object' && obj !== null) {
    // If the current object is an object, create a new object without the excluded keys
    const newObj: Record<string, unknown> = {}

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !excludeKeys.includes(key)) {
        // Recursively process each key except those in the exclude list
        newObj[key] = removeKeysRecursively(obj[key], excludeKeys)
      }
    }

    return newObj as T
  }

  // Return the value directly if it's neither an object nor an array
  return obj
}

/**
 * Recursively checks if an object or array contains an element with a specified state.

 * @param {unknown} obj - The object or array to check.
 * @param {string[]} targetStates - An array of target states to check for.
 * @returns {boolean} `true` if any element in the object or array has a matching state, `false` otherwise.

 * @description
 * This function recursively traverses an object or array to determine if any element within it has a state that matches one of the specified target states.

 * **For arrays:**
 * - It iterates over each element in the array and recursively checks if any element within it has a matching state.
 * - If a match is found, it returns `true` immediately.

 * **For objects:**
 * - It checks if the object itself has a `state` property and if that state matches any of the target states.
 * - If a match is found, it returns `true` immediately.
 * - Otherwise, it recursively checks the values of all properties in the object.

 * **For other values:**
 * - If the value is not an object or array, it returns `false`.
*/
const isAnyInSpecifiedStates = (obj: unknown, targetStates: string[]): boolean => {
  if (Array.isArray(obj)) {
    // If the object is an array, check each element recursively
    return obj.some((item) => isAnyInSpecifiedStates(item, targetStates))
  } else if (typeof obj === 'object' && obj !== null && 'state' in obj) {
    // If the object is a regular object, check if it has the target state
    if (targetStates.includes(obj.state as string)) {
      return true
    }

    // Recursively check all properties of the object
    return Object.values(obj).some((value) => isAnyInSpecifiedStates(value, targetStates))
  }

  // Return false if it's neither an object nor an array
  return false
}

export {
  getLastCollectedDate,
  getEntityId,
  updateEntity,
  transformRecommendedActionToClosedRecommendedAction,
  onComponentChange,
  buildFormErrorStruct,
  validateFault,
  validateRecommendedAction,
  isFaultFormValid,
  removeKeysRecursively,
  isAnyInSpecifiedStates,
  revalidateFaultFormErrors,
}
