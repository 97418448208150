import { css } from '@emotion/react'
import { FC } from 'react'
import { YearMonth } from '@/models/widgets/asset-data-collection/types'
import { ConditionStatusWidgetDataType } from '@/models/widgets/asset-health-condition/types'
import { dataTestId } from '@/tests/testid'
import AHCTableRow from './table-row/AHCTableRow'
import { StyledAHCWidgetTable, StyledAHCNoDataContainer } from '../styled'
import { useDashboardContext } from '@/contexts/dashboard'
import { isEqual } from '@/shared/utils'

interface AssetHealthConditionTableProps {
  slices: ConditionStatusWidgetDataType
  selectedPeriod: YearMonth
  showPercentages: boolean
  noDataInAllMonth: boolean
  headerWidth: string
}

const AssetHealthConditionTable: FC<AssetHealthConditionTableProps> = ({
  slices,
  selectedPeriod,
  showPercentages,
  noDataInAllMonth,
  headerWidth,
}) => {
  const { dashboardFilters } = useDashboardContext()
  const selectedColumn = slices.findIndex(({ period }) => isEqual(period, selectedPeriod))

  return (
    (noDataInAllMonth && <StyledAHCNoDataContainer>No Data</StyledAHCNoDataContainer>) || (
      <StyledAHCWidgetTable headerWidth={headerWidth} data-testid={dataTestId.assetHealthConditionTrendViewTable}>
        <colgroup>
          <col span={selectedColumn + 1} />
          <col
            span={1}
            css={css`
              background-color: #bfdbfe99;
            `}
          />
        </colgroup>
        <tbody>
          {dashboardFilters.assetStatus.includes('severe') && (
            <AHCTableRow severity={'severe'} slices={slices} showPercentages={showPercentages} />
          )}
          {dashboardFilters.assetStatus.includes('unacceptable') && (
            <AHCTableRow severity={'unacceptable'} slices={slices} showPercentages={showPercentages} />
          )}
          {dashboardFilters.assetStatus.includes('unsatisfactory') && (
            <AHCTableRow severity={'unsatisfactory'} slices={slices} showPercentages={showPercentages} />
          )}
          {dashboardFilters.assetStatus.includes('acceptable') && (
            <AHCTableRow severity={'acceptable'} slices={slices} showPercentages={showPercentages} />
          )}
          {dashboardFilters.assetStatus.includes('normal') && (
            <AHCTableRow severity={'normal'} slices={slices} showPercentages={showPercentages} />
          )}
        </tbody>
      </StyledAHCWidgetTable>
    )
  )
}

export default AssetHealthConditionTable
