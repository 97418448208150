import { ChannelInterface, ChannelStatusEnum } from '@/models/devicesAndSensors/types'
import ChannelAssetsCell from '@/modules/devicesSensors/components/channelCells/channelAssetsCell'
import ChannelNameCell from '@/modules/devicesSensors/components/channelCells/channelNameCell'
import LastCommunicationCell from '@/modules/devicesSensors/components/commonCells/lastCommunicationCell'
import StatusCell from '@/modules/devicesSensors/components/commonCells/statusCell'
import { hasNoMeasurementEverData } from '@/modules/devicesSensors/utils'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useChannelsColumnsDefinition = () => {
  const columns = useMemo<MRT_ColumnDef<ChannelInterface>[]>(
    () => [
      {
        id: 'hiddenTopColumn',
        header: '',
        size: 60,
      },
      {
        accessorKey: 'number',
        header: '',
        size: 150,
        Cell: ({ row, cell }) => (
          <ChannelNameCell sensorName={row.original.name} sensorNumber={cell.getValue<number>()} />
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 100,
        Cell: ({ row, cell }) => {
          const sensorStatus = hasNoMeasurementEverData(row.original.faults)
            ? ChannelStatusEnum.NoSensorData
            : cell.getValue<ChannelStatusEnum>()
          return <StatusCell sensorStatus={sensorStatus} />
        },
      },
      {
        accessorKey: 'lastCommunicationTime',
        header: 'Last Communication',
        size: 180,
        Cell: ({ cell }) => <LastCommunicationCell lastCommunicationDate={cell.getValue<string>()} />,
      },
      {
        accessKey: 'uniquePlaces',
        header: 'Connected Assets',
        size: 160,
        Cell: ({ row }) => <ChannelAssetsCell assets={row.original.uniquePlaces} />,
      },
      {
        id: 'hiddenBottomColumn',
        header: '',
        size: 60,
      },
    ],
    []
  )

  return columns
}

export default useChannelsColumnsDefinition
