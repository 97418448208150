import useWindowDimensions from '@/shared/hooks/useWindowDimensions'
import { useMemo } from 'react'

const useGetHeaderCharactersLength = () => {
  const { width } = useWindowDimensions()

  const characterLengths = useMemo(() => {
    if (width <= 1200) {
      return {
        number: 2,
        status: 1,
        name: 7,
        lastCommunicationDate: 7,
        assetName: 7,
      }
    } else if (width <= 1440) {
      return {
        number: 4,
        status: 10,
        name: 10,
        lastCommunicationDate: 20,
        assetName: 20,
      }
    } else if (width <= 1680) {
      return {
        number: 4,
        status: 5,
        name: 10,
        lastCommunicationDate: 17,
        assetName: 14,
      }
    } else if (width <= 1800) {
      return {
        number: 4,
        status: 6,
        name: 10,
        lastCommunicationDate: 18,
        assetName: 16,
      }
    } else {
      return {
        number: 12,
        status: 15,
        name: 15,
        lastCommunicationDate: 18,
        assetName: 26,
      }
    }
  }, [width])

  return characterLengths
}
export default useGetHeaderCharactersLength
