import { StyledThreshold, StyledThresholdTitleValue } from '@/modules/devicesSensors/styled'
import { FC } from 'react'

interface ThresholdValuesProps {
  executeFnTemp: (temp: string | null, unit: string) => string
  executeFnBuffer: (buffer: string | null, unit: string) => string
  warningHighLevelTemp: string | null
  warningHighLevelBuffer: string | null
  unitBuffer: string
  unitTemp: string
  title: string
}

const ThresholdValues: FC<ThresholdValuesProps> = ({
  executeFnTemp,
  warningHighLevelBuffer,
  warningHighLevelTemp,
  executeFnBuffer,
  unitTemp,
  unitBuffer,
  title,
}) => {
  return (
    <StyledThreshold>
      <StyledThresholdTitleValue>{title}</StyledThresholdTitleValue>
      <div>{executeFnTemp(warningHighLevelTemp, unitTemp)}</div>
      <div>{executeFnBuffer(warningHighLevelBuffer, unitBuffer)}</div>
    </StyledThreshold>
  )
}
export default ThresholdValues
