import { Checkbox, COLORS, Icon, SIZES, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledUnresolvedRecommendationsRoot = styled.div`
  gap: ${SPACINGS.md};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2rem ${SPACINGS.lg} 0;
  padding: 0 2rem;
`
export const StyledURStatusCheckbox = styled(Checkbox)`
  font-size: '1rem !important';
`

export const StyledUnresolvedRecommendationTable = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  thead {
    background-color: ${COLORS.gray100};
    margin: 0;
    position: sticky;
    top: 0;
  }

  th {
    text-align: left;
    white-space: nowrap;
    user-select: none;
  }

  th > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  th,
  td {
    padding: ${SPACINGS.xs} ${SPACINGS.md};
  }
`
export const StyledUnresolvedRecommendationTableRow = styled.tr<{ isOdd: boolean; isSelected: boolean }>`
  width: 100%;
  height: 46px;
  background-color: ${({ isOdd, isSelected }) =>
    isSelected ? COLORS.primary300 : isOdd ? COLORS.gray050 : 'transparent'};

  &:hover {
    cursor: pointer;
    ${({ isSelected }) => !isSelected && `background-color: ${COLORS.primary200};`}
  }

  &:focus-visible {
    outline: none;
  }
`
export const StyledTableCellContent = styled.div<{ textAlignCondition: string }>`
  overflow-wrap: anywhere;
  text-align: ${({ textAlignCondition }) => (textAlignCondition === '' ? 'center' : 'left')};
`
export const StyledDaysDueSpan = styled.span<{ backgroundColor: string; color: string }>`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0.1875rem 0.3125rem 0.1875rem 0.5rem;
  border-radius: 0.3125rem;
`
export const StyledDaysDueContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledAssetStatusContainer = styled.div`
  width: ${SIZES.s20};
  height: ${SIZES.s20};
  margin: auto;
`
export const StyledAssetStatusIcon = styled(Icon)`
  width: auto;
  height: auto;
`
