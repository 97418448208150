import { getRowBackgroundColor } from '@/modules/devices-sensors/utils/tableUtils'
import { COLORS } from '@skf-internal/ui-components-react'
/******************Device table sx props******************** */
export const deviceTablePaperProps = {
  sx: {
    marginTop: '1.5rem',
  },
}

export const deviceTableHeaderProps = {
  sx: {
    '& th': {
      backgroundColor: COLORS.gray100,
      padding: '0 0.25rem',
      textAlign: 'left',
      font: '1rem / 1.25 SKFSans, Arial, sans-serif',
      justifyContent: 'center',
      alignItems: 'space-between',
      '& div': {
        fontWeight: 700,
        margin: '0.2rem 0',
        marginRight: '0.1rem',
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& th:last-of-type': {
      paddingLeft: 0,
    },
  },
}

export const deviceTableDetailsPanelProps = {
  sx: {
    padding: 0,
  },
}

export const deviceTableContainerProps = {
  sx: {
    height: 'calc(100vh - 21rem)',
  },
}

export const deviceTableBodyCellProps = {
  sx: {
    font: '1rem / 1.25 SKFSans, Arial, sans-serif',
    padding: '0.5rem 1rem',
    '&:focus-visible': {
      outline: 'none',
    },
  },
}

export const deviceTableHeadCellProps = {
  sx: {
    '& .MuiTableSortLabel-root': {
      color: COLORS.gray500,
    },
    '& button:has(#fakeFilterIconId)': {
      display: 'none',
    },
  },
}
export const deviceTableExpandButtonProps = {
  sx: {
    color: COLORS.brand,
  },
}

export const deviceTableBodyRowProps = (isDetailPanel: boolean, rowIndex: number, isSelected: boolean) => ({
  sx: {
    backgroundColor: () => {
      let rowColor = 'transparent'

      if (!isDetailPanel) {
        rowColor = getRowBackgroundColor(!!(rowIndex % 2), isSelected)
      }
      return rowColor
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: !isSelected && COLORS.primary200,
      '& td': {
        ':after': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .MuiTableCell-root': {
      '&:focus-visible': {
        outline: 'none',
      },
    },
  },
})
/****************************************************** */

/**************Channels table sx props */
export const channelTableContainerProps = {
  sx: { maxHeight: 'unset' },
}

export const channelTablePaperProps = {
  sx: {
    width: '100vw',
    padding: 0,
  },
}

export const channelTableBodyRowProps = (isDetailPanel: boolean, rowIndex: number, isSelected: boolean) => ({
  sx: {
    backgroundColor: () => {
      let rowColor = 'transparent'

      if (!isDetailPanel) {
        rowColor = getRowBackgroundColor(!!(rowIndex % 2), isSelected)
      }
      return rowColor
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: !isSelected && COLORS.primary200,
      '& td': {
        ':after': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
})

export const channelTableBodyCellProps = {
  sx: {
    font: '1rem / 1.25 SKFSans, Arial, sans-serif',
    padding: '0.5rem 1rem',
    '&:focus-visible': {
      outline: 'none',
    },
  },
}
