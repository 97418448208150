import useContextWrapper from '@/shared/hooks/useContextWrapper'
import {
  DevicesAndSensorsData,
  DevicesAndSensorsSelectedItemsData,
  DevicesAndSensorsSelectedItemsDataNew,
  DevicesAndSensorsTableData,
  DevicesSensorsTableDeviceRowData,
} from '@/models/devicesAndSensors/types'
import { createContext, useMemo, useState } from 'react'

interface IDevicesAndSensorsContext {
  devicesAndSensorsData: DevicesSensorsTableDeviceRowData[]
  setDevicesAndSensorsData: React.Dispatch<React.SetStateAction<IDevicesAndSensorsContext['devicesAndSensorsData']>>

  devicesAndSensorsSelectedItemsData: DevicesAndSensorsSelectedItemsData
  setDevicesAndSensorsSelectedItemsData: React.Dispatch<
    React.SetStateAction<IDevicesAndSensorsContext['devicesAndSensorsSelectedItemsData']>
  >
  devicesAndSensorsTableData: DevicesAndSensorsTableData
  setDevicesAndSensorsTableData: React.Dispatch<
    React.SetStateAction<IDevicesAndSensorsContext['devicesAndSensorsTableData']>
  >

  devicesAndSensorsDataNew: DevicesAndSensorsData['devices']
  setDevicesAndSensorsDataNew: React.Dispatch<
    React.SetStateAction<IDevicesAndSensorsContext['devicesAndSensorsDataNew']>
  >

  devicesAndSensorsSelectedItemsDataNew: DevicesAndSensorsSelectedItemsDataNew
  setDevicesAndSensorsSelectedItemsDataNew: React.Dispatch<
    React.SetStateAction<IDevicesAndSensorsContext['devicesAndSensorsSelectedItemsDataNew']>
  >
}

export const DevicesAndSensorsContext = createContext<IDevicesAndSensorsContext | null>(null)

export const useDevicesAndSensorsContext = () =>
  useContextWrapper(DevicesAndSensorsContext, {
    contextName: useDevicesAndSensorsContext.name,
    providerName: DevicesAndSensorsContextProvider.name,
  })

export const DevicesAndSensorsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [devicesAndSensorsData, setDevicesAndSensorsData] = useState<
    IDevicesAndSensorsContext['devicesAndSensorsData']
  >([])

  const [devicesAndSensorsDataNew, setDevicesAndSensorsDataNew] = useState<
    IDevicesAndSensorsContext['devicesAndSensorsDataNew']
  >([])

  const [devicesAndSensorsSelectedItemsData, setDevicesAndSensorsSelectedItemsData] = useState<
    IDevicesAndSensorsContext['devicesAndSensorsSelectedItemsData']
  >({
    device: undefined,
    channel: undefined,
    channelIndex: undefined,
  })

  const [devicesAndSensorsTableData, setDevicesAndSensorsTableData] = useState<
    IDevicesAndSensorsContext['devicesAndSensorsTableData']
  >({
    sorting: [
      {
        desc: false,
        id: 'number',
      },
    ],
    columnFilters: [],
    expanded: {},
  })

  const [devicesAndSensorsSelectedItemsDataNew, setDevicesAndSensorsSelectedItemsDataNew] = useState<
    IDevicesAndSensorsContext['devicesAndSensorsSelectedItemsDataNew']
  >({
    device: undefined,
    channel: undefined,
    channelIndex: undefined,
  })

  const value = useMemo(
    () => ({
      setDevicesAndSensorsData,
      devicesAndSensorsData: devicesAndSensorsData.sort((a, b) => a.number - b.number),
      devicesAndSensorsSelectedItemsData,
      setDevicesAndSensorsSelectedItemsData,
      devicesAndSensorsTableData,
      setDevicesAndSensorsTableData,
      devicesAndSensorsDataNew,
      setDevicesAndSensorsDataNew,
      devicesAndSensorsSelectedItemsDataNew,
      setDevicesAndSensorsSelectedItemsDataNew,
    }),
    [
      devicesAndSensorsData,
      devicesAndSensorsSelectedItemsData,
      devicesAndSensorsTableData,
      devicesAndSensorsDataNew,
      devicesAndSensorsSelectedItemsDataNew,
    ]
  )

  return <DevicesAndSensorsContext.Provider value={value}>{children}</DevicesAndSensorsContext.Provider>
}
