import { getDevicesAndSensorsData } from '@/api/paths/devicesAndSensors'
import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { DevicesAndSensorsData } from '@/models/devicesAndSensors/types'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'

const useNewGetDevicesAndSensorsData = () => {
  const { setDevicesAndSensorsDataNew } = useDevicesAndSensorsContext()

  const { sid: companyId } = useParams()

  const throwError = useAsyncThrow()

  return useApi(() => {
    return getDevicesAndSensorsData(
      {
        onSuccess: async (result: DevicesAndSensorsData) => {
          setDevicesAndSensorsDataNew(result.devices)
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinError(error))
        },
      },
      {
        companyId,
      }
    )
  })
}

export default useNewGetDevicesAndSensorsData
