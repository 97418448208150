import { Flexbox, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledModuleHeader = styled(Flexbox)`
  gap: ${SPACINGS.lg};
`

export const StyledHeaderActionPanel = styled(Flexbox)<{ isWithUserEvents: boolean }>`
  gap: ${SPACINGS.md};
  padding-top: 20px;
  pointer-events: ${({ isWithUserEvents }) => (isWithUserEvents ? 'none' : 'auto')};
`
