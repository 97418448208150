import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { DeviceInterface } from '@/models/devicesAndSensors/types'
import useDevicesColumnsDefinition from '@/modules/devicesSensors/columns/useDevicesColumnsDefinition'
import ChannelsPane from '@/modules/devicesSensors/components/channelsPane'
import {
  deviceTableBodyCellProps,
  deviceTableExpandButtonProps,
  deviceTableContainerProps,
  deviceTableDetailsPanelProps,
  deviceTableHeadCellProps,
  deviceTableHeaderProps,
  deviceTablePaperProps,
  deviceTableBodyRowProps,
} from '@/modules/devicesSensors/styled/tableSx'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { Icon } from '@skf-internal/ui-components-react'
import { MRT_Row, useMaterialReactTable } from 'material-react-table'
import { useCallback, useMemo } from 'react'

const useDevicesTable = () => {
  let tableRows: MRT_Row<DeviceInterface>[] = []

  const { devicesAndSensorsDataNew, setDevicesAndSensorsSelectedItemsDataNew, devicesAndSensorsSelectedItemsDataNew } =
    useDevicesAndSensorsContext()

  const { device } = devicesAndSensorsSelectedItemsDataNew

  const columns = useDevicesColumnsDefinition()

  const onExpandButtonClick = useDeepCompareCallback(
    (row: DeviceInterface) => {
      setDevicesAndSensorsSelectedItemsDataNew({
        ...devicesAndSensorsSelectedItemsDataNew,
        device: row,
        channel: undefined,
        // channelIndex: 0,
      })
    },
    [devicesAndSensorsSelectedItemsDataNew]
  )

  const onDeviceTableRowClick = useCallback(
    (row: MRT_Row<DeviceInterface>) => {
      row.toggleExpanded()
      setDevicesAndSensorsSelectedItemsDataNew({
        ...devicesAndSensorsSelectedItemsDataNew,
        device: row.original,
        channel: undefined,
        //channelIndex: 0,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [devicesAndSensorsSelectedItemsDataNew]
  )

  const onDeviceTableRowKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTableRowElement>, row: MRT_Row<DeviceInterface>, rowIndex: number) => {
      if (event.key === 'Enter' && row.original.channels.length > 0) {
        row.toggleExpanded()
      }
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        if (event.key === 'ArrowUp' && rowIndex === 0) return
        if (event.key === 'ArrowDown' && rowIndex === tableRows.length - 1) return

        if (event.key === 'ArrowDown') {
          // if (!row.getIsExpanded()) {
          setDevicesAndSensorsSelectedItemsDataNew({
            ...devicesAndSensorsSelectedItemsDataNew,
            device: tableRows[rowIndex + 1].original,
            channel: undefined,
          })
          //}
        }
        if (event.key === 'ArrowUp') {
          setDevicesAndSensorsSelectedItemsDataNew({
            ...devicesAndSensorsSelectedItemsDataNew,
            device: tableRows[rowIndex - 1].original,
            channel: undefined,
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [devicesAndSensorsDataNew]
  )

  const deviceTableIcons = useMemo(
    () => ({
      KeyboardDoubleArrowDownIcon: () => <div />,
      SyncAltIcon: () => <Icon feSize="sm" feIcon="caretUpDown" />,
      ArrowDownwardIcon: ({ className }: { className: string }) => {
        let result = <Icon feSize="sm" feIcon="caretUp" />
        if ((className as string).includes('DirectionDesc')) {
          result = <Icon feSize="sm" feIcon="caretDown" />
        }
        return result
      },
      FilterAltIcon: () => <div id="fakeFilterIconId" />,
    }),
    []
  )

  const devicesTable = useMaterialReactTable({
    columns,
    data: devicesAndSensorsDataNew,
    enableRowVirtualization: true,

    rowVirtualizerOptions: {
      overscan: 5,
      estimateSize: () => 50,
    },
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableMultiSort: true,
    maxMultiSortColCount: 3,
    isMultiSortEvent: () => true,
    initialState: {
      sorting: [{ id: 'deviceNumber', desc: false }],
    },
    state: {
      showSkeletons: devicesAndSensorsDataNew.length === 0,
    },
    renderEmptyRowsFallback: () => <div />,
    muiTablePaperProps: deviceTablePaperProps,
    muiTableHeadProps: deviceTableHeaderProps,
    muiDetailPanelProps: deviceTableDetailsPanelProps,
    muiTableContainerProps: deviceTableContainerProps,
    defaultColumn: {
      muiTableBodyCellProps: deviceTableBodyCellProps,
      muiTableHeadCellProps: deviceTableHeadCellProps,
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        size: 10,
      },
    },
    muiExpandButtonProps: ({ row }) => {
      return {
        ...deviceTableExpandButtonProps,
        onClick: () => onExpandButtonClick(row.original),
      }
    },
    muiTableBodyRowProps: ({ row, isDetailPanel }) => {
      const rowIndex: number = tableRows.findIndex((item) => item.id === row.id)
      const isSelected = device?.deviceID === row.original.deviceID
      return {
        ...deviceTableBodyRowProps(!!isDetailPanel, rowIndex, isSelected),
        tabIndex: 0,
        onClick: () => onDeviceTableRowClick(row),
        onKeyDown: (e) => onDeviceTableRowKeyDown(e, row, rowIndex),
      }
    },
    columnFilterDisplayMode: 'custom',
    enableExpanding: true,
    positionExpandColumn: 'last',
    renderDetailPanel: ({ row }) => {
      return <ChannelsPane channels={row.original.channels} device={row.original} />
    },
    icons: deviceTableIcons,
  })

  const tableState = devicesTable.getState()

  const tableSorting = useDeepCompareMemo(() => tableState.sorting, [tableState])
  const tableColumnFilters = useDeepCompareMemo(() => tableState.columnFilters, [tableState])

  tableRows = useDeepCompareMemo(
    () => devicesTable.getRowModel().rows,
    [devicesAndSensorsDataNew, tableSorting, tableColumnFilters]
  )

  useDeepCompareEffect(() => {
    const rows = tableRows.map((v) => v.original)
    if (rows.length > 0) {
      setDevicesAndSensorsSelectedItemsDataNew({ ...devicesAndSensorsSelectedItemsDataNew, device: rows[0] })
    } else {
      setDevicesAndSensorsSelectedItemsDataNew({ ...devicesAndSensorsSelectedItemsDataNew, device: undefined })
    }
  }, [tableRows])

  return devicesTable
}

export default useDevicesTable
