import useWindowDimensions from '@/shared/hooks/useWindowDimensions'
import { useMemo } from 'react'

const useGetDataSectionCharactersLength = () => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 20
    } else if (width <= 1440) {
      result = 20
    } else if (width <= 1700) {
      result = 22
    } else {
      result = 28
    }
    return result
  }, [width])

  return characterLength
}

export default useGetDataSectionCharactersLength
