import { ChannelFaultEnum, ChannelStatusEnum } from '@/models/devicesAndSensors/types'
import { ChannelStatusIcon, ChannelStatusString, ChannelTextColor } from '@/modules/devices-sensors/utils/constants'
import { channelFaultText } from '@/modules/devicesSensors/utils/constants'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Icon, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const ChannelDataFault: FC<{
  status: ChannelStatusEnum
  fault?: ChannelFaultEnum
}> = ({ status, fault }) => {
  const iconData = ChannelStatusIcon[status]
  const isFaulted = fault && status

  return (
    <Flexbox data-testid={dataTestId.channelDataFault} feFlexDirection="row" feGap="xs" feAlignItems="center">
      <Icon feIcon={iconData.icon} feColor={iconData.color} feSize="sm" />
      <Text feColor={ChannelTextColor[status]} feFontWeight="bold">
        {isFaulted && channelFaultText[fault]}
        {!isFaulted && status !== 'channelNotConnected' && ChannelStatusString['normal']}
        {!isFaulted && status === 'channelNotConnected' && ChannelStatusString['channelNotConnected']}
      </Text>
    </Flexbox>
  )
}

export default ChannelDataFault
