import { AssetReportFilters } from '@/models/reportGenerator/types'
import { allAssetTypes, assetCriticalities } from '@/modules/report-generator/utils/constants'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { AssetStatus } from '@/shared/models/types'
import { getEnumKeys } from '@/shared/utils'
import { createContext, useMemo, useState } from 'react'

export interface IReportGeneratorContext {
  generatedReportData: string[]
  assetReportFilters: AssetReportFilters
  setAssetReportFilters: React.Dispatch<React.SetStateAction<IReportGeneratorContext['assetReportFilters']>>
  isReportFiltersApplied: boolean
  setIsReportFiltersApplied: React.Dispatch<React.SetStateAction<IReportGeneratorContext['isReportFiltersApplied']>>
}
export const ReportGeneratorContext = createContext<IReportGeneratorContext | null>(null)

export const useReportGeneratorContext = () =>
  useContextWrapper(ReportGeneratorContext, {
    contextName: useReportGeneratorContext.name,
    providerName: ReportGeneratorContextProvider.name,
  })

export const ReportGeneratorContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [assetReportFilters, setAssetReportFilters] = useState<IReportGeneratorContext['assetReportFilters']>({
    assets: [],
    assetStatus: getEnumKeys(AssetStatus, ['never-reported']),
    assetCriticality: [...assetCriticalities],
    assetType: allAssetTypes,
    reportDate: new Date(),
    reportType: [],
  })
  const [generatedReportData] = useState<IReportGeneratorContext['generatedReportData']>([])
  const [isReportFiltersApplied, setIsReportFiltersApplied] =
    useState<IReportGeneratorContext['isReportFiltersApplied']>(false)

  const value = useMemo(
    () => ({
      generatedReportData,
      assetReportFilters,
      setAssetReportFilters,
      isReportFiltersApplied,
      setIsReportFiltersApplied,
    }),
    [assetReportFilters, generatedReportData, isReportFiltersApplied]
  )

  return <ReportGeneratorContext.Provider value={value}>{children}</ReportGeneratorContext.Provider>
}
