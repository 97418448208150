import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { ChannelInterface, DeviceInterface } from '@/models/devicesAndSensors/types'
import useSensorsColumnsDefinition from '@/modules/devicesSensors/columns/useChannelsColumnsDefinition'
import {
  channelTableBodyCellProps,
  channelTableBodyRowProps,
  channelTableContainerProps,
  channelTablePaperProps,
} from '@/modules/devicesSensors/styled/tableSx'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { useMaterialReactTable } from 'material-react-table'

interface ChannelTableProps {
  channelsData: ChannelInterface[]
  parentDevice: DeviceInterface
}

const useChannelTable = ({ channelsData, parentDevice }: ChannelTableProps) => {
  const columns = useSensorsColumnsDefinition()

  const { setDevicesAndSensorsSelectedItemsDataNew, devicesAndSensorsSelectedItemsDataNew } =
    useDevicesAndSensorsContext()

  const { channel } = devicesAndSensorsSelectedItemsDataNew

  const onChannelRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: ChannelInterface) => {
    event.stopPropagation()
    setDevicesAndSensorsSelectedItemsDataNew({
      ...devicesAndSensorsSelectedItemsDataNew,
      device: parentDevice,
      channel: row,
    })
  }

  const channelsTable = useMaterialReactTable({
    columns,
    data: channelsData,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableTableHead: false,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 1, estimateSize: () => 10 },
    muiTableContainerProps: channelTableContainerProps,
    muiTablePaperProps: channelTablePaperProps,
    muiTableBodyRowProps: ({ row, isDetailPanel }) => {
      const rowIndex: number = tableRows.findIndex((item) => item.id === row.id)
      const isSelected = channel?.number === row.original.number && channel?.type === row.original.type
      return {
        ...channelTableBodyRowProps(!!isDetailPanel, rowIndex, isSelected),
        tabIndex: 0,
        onClick: (event) => onChannelRowClick(event, row.original),
        // onKeyDown: (event) => {
        //   event.stopPropagation()
        //   if (event.key === 'ArrowUp' && rowIndex === 0) return
        //   if (event.key === 'ArrowDown' && rowIndex === tableRows.length - 1) return

        //   if (event.key === 'ArrowDown') {
        //     setDevicesAndSensorsSelectedItemsDataNew({
        //       ...devicesAndSensorsSelectedItemsDataNew,
        //       device,
        //       channel: tableRows[rowIndex + 1].original,
        //     })
        //   }
        //   if (event.key === 'ArrowUp') {
        //     setDevicesAndSensorsSelectedItemsDataNew({
        //       ...devicesAndSensorsSelectedItemsDataNew,
        //       device,
        //       channel: tableRows[rowIndex - 1].original,
        //     })
        //   }
        // },
      }
    },
    defaultColumn: {
      muiTableBodyCellProps: channelTableBodyCellProps,
    },
  })

  const tableRows = useDeepCompareMemo(() => channelsTable.getRowModel().rows, [channelsData])

  return channelsTable
}

export default useChannelTable
