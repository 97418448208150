import useOutsideClick from '@/shared/hooks/useOutsideClick'
import GenericConfirmationDialogButton from '@/shared/components/GenericConfirmationDialog/GenericConfirmationDialogButton'
import { ROUTES } from '@/shared/constants'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import { useAppDispatch } from '@/store/store'
import { IconButton, Popup, css, useTooltip } from '@skf-internal/ui-components-react'
import { FC, useId, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledLink, StyledPopover } from './styled-popover'
import { useAnalysisBacklogContext } from '@/contexts/analysisBacklog'
import { AssetRow } from '@/models/analysisBacklog/types'
import useMarkReportAsNormal from '@/modules/analysis-backlog/hooks/useMarkReportAsNormal'
import { ColumnFiltersState, SortingState } from '@tanstack/react-table'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'

type ActionMenuProps = {
  elements: MenuItem[]
  assetRow: AssetRow
  columnFilters: ColumnFiltersState
  sorting: SortingState
  tableState: boolean
}

export const ActionMenu: FC<ActionMenuProps> = (props) => (
  <div
    css={css`
      display: flex;
      align-items: end;
    `}
  >
    <Menu {...props} />
  </div>
)

export type MenuItem = 'mark-as-normal' | 'report-status'

type MenuPopoverContext = ActionMenuProps & {
  closePopover: (v: boolean) => void
}

const PopoverContent: FC<MenuPopoverContext> = ({
  elements,
  assetRow,
  columnFilters,
  sorting,
  closePopover,
  tableState,
}) => {
  const { asset, belongsTo } = assetRow
  const navigate = useNavigate()
  const { tableScrollPosition } = useAnalysisBacklogContext()
  const { sid } = useParams()
  const handleClick = () => {
    sessionStorage.setItem(STORAGE_REPORT_STATUS_ORIGIN, `${ROUTES.ANALYSIS_BACKLOG}/${sid}`)
    navigate(`${ROUTES.REPORT_STATUS}/${assetRow.asset.id}`, {
      state: { columnFilters, sorting, tableState, tableScrollPosition },
    })
    closePopover(false)
  }

  const actionsMenuDispatch = useAppDispatch()

  const closeDialog = () => {
    actionsMenuDispatch(closeGenericConfirmDialog())
  }

  const { id: assetId, name: assetName } = asset
  const { execute } = useMarkReportAsNormal(assetId, sid as string)

  const handleConfirmClick = () => {
    actionsMenuDispatch(
      showGenericConfirmDialog({
        isOpen: true,
        title: 'Mark Asset as Normal',
        message: `Mark asset ${assetName} from ${belongsTo.name} as Normal status?`,
        buttons: [
          <GenericConfirmationDialogButton type="secondary" label="Cancel" onClick={() => closeDialog()} />,
          <GenericConfirmationDialogButton
            type="primary"
            label="Confirm"
            onClick={() => {
              execute()
              closeDialog()
            }}
          />,
        ],
      })
    )
  }

  return (
    <div>
      {elements.includes('mark-as-normal') && (
        <StyledLink
          as="button"
          type="button"
          feIcon={{ feIcon: 'checkCircle', position: 'left' }}
          feStretch={true}
          onClick={() => {
            handleConfirmClick()
            closePopover(false)
          }}
        >
          Mark as Normal
        </StyledLink>
      )}
      {elements.includes('report-status') && (
        <StyledLink
          as="button"
          type="button"
          feIcon={{ feIcon: 'recAction', position: 'left' }}
          feStretch={true}
          onClick={handleClick}
        >
          Report status
        </StyledLink>
      )}
    </div>
  )
}
const Menu: FC<ActionMenuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const outsideClickRef = useRef<HTMLDivElement>(null)
  useOutsideClick(
    () => {
      setIsOpen(false)
    },
    {
      refs: [outsideClickRef],
    }
  )
  const [clickedRef] = useTooltip()

  const headingId = useId()

  return (
    <div>
      <Popup
        isHovered={isOpen}
        triggerElement={clickedRef.current}
        css={css`
          padding: 0 !important;
        `}
      >
        <StyledPopover className="Popover" ref={outsideClickRef} aria-labelledby={headingId}>
          <PopoverContent {...props} closePopover={() => setIsOpen(false)} />
        </StyledPopover>
      </Popup>

      <IconButton
        aria-label="Actions"
        as="button"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        ref={clickedRef}
        feIcon="kebabMore"
        feSize="md"
      />
    </div>
  )
}
