import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { Flexbox, GridItem, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { StyledDataGrid, StyledDeviceModel } from '@/modules/devices-sensors/components/styled'
import { deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import DeviceStatusIndicator from '@/modules/devicesSensors/components/sideMenu/deviceSection/deviceStatusIndicator'
import SensorImage from '@/modules/devicesSensors/components/sideMenu/common/sensorImage'
import { transformDeviceModelStatus } from '@/modules/devicesSensors/utils'
import DataRow from '@/modules/devicesSensors/components/sideMenu/common/dataRow'
import { formatInternalTemp, formatUsedBuffer } from '@/modules/devices-sensors/utils'
import ColoredValue from '@/modules/devicesSensors/components/sideMenu/deviceSection/coloredValue'
import ThresholdValues from '@/modules/devicesSensors/components/sideMenu/deviceSection/thresholdValues'
import {
  StyledData,
  StyledThresholdValuesWrapper,
  StyledThresholdWrapper,
  StyledThresholdsDataWrapper,
  StyledTitle,
  StyledTitleThreshold,
  StyledValuesWrapper,
} from '@/modules/devicesSensors/styled'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import CenteredLoader from '@/shared/components/CenteredLoader'
import { dataTestId } from '@/tests/testid'
import { formatProp } from '@/shared/utils'

const DeviceDataSection: FC = () => {
  const {
    devicesAndSensorsSelectedItemsDataNew: { device: deviceData },
  } = useDevicesAndSensorsContext()

  const inLoadingDataMode = useDeepCompareMemo(
    () => !deviceData || Object.values(deviceData).every((val) => val === null),
    [deviceData]
  )

  if ((inLoadingDataMode || !deviceData) && deviceData !== undefined) return <CenteredLoader />

  if (deviceData === undefined) return <></>

  return (
    <Flexbox data-testid={dataTestId.deviceDataSection} feFlexDirection="column" feGap="md">
      <Flexbox feFlexDirection="row" feJustifyContent="space-between"></Flexbox>
      <StyledDataGrid>
        <>
          <GridItem data-testid="data-row">
            <Flexbox feFlexDirection="column" feGap="md">
              <Text feFontSize="xxl" feFontWeight="bold">
                {deviceNumberFormatter.format(deviceData.deviceNumber)}. {deviceData.deviceName}
              </Text>

              <DeviceStatusIndicator faults={deviceData.faults} />
            </Flexbox>
          </GridItem>
          <GridItem>
            <Flexbox feAlignItems="end">
              <StyledDeviceModel feFontSize="lg" feFontWeight="bold">
                {deviceData.model}
              </StyledDeviceModel>
              <SensorImage model={transformDeviceModelStatus(deviceData.model)} />
            </Flexbox>
          </GridItem>
        </>

        <DataRow
          name="Last Communication"
          value={formatProp(deviceData.lastCommunicationDate, (prop) =>
            formatDate(prop, DateFormats.AmericanDateTimeFormat)
          )}
        />
      </StyledDataGrid>
      <StyledData>
        <StyledTitle>
          <div>Firmware Version</div>
          <div>Internal Temperature</div>
          <div>Used Buffer</div>
        </StyledTitle>
        <StyledThresholdWrapper>
          <StyledValuesWrapper>
            <Text feFontWeight="bold">{formatProp(deviceData.firmwareVersion, (prop) => prop)}</Text>
            <ColoredValue
              value={deviceData.internalTemp.value}
              warningHighLevel={deviceData.internalTemp.warningHighLevel}
              alarmHighLevel={deviceData.internalTemp.alarmHighLevel}
              executeFn={formatInternalTemp}
              unit={deviceData.internalTemp.unit}
            />
            <ColoredValue
              value={deviceData.usedBufferObject.value}
              warningHighLevel={deviceData.usedBufferObject.warningHighLevel}
              alarmHighLevel={deviceData.usedBufferObject.alarmHighLevel}
              executeFn={formatUsedBuffer}
              unit={deviceData.usedBufferObject.unit}
            />
          </StyledValuesWrapper>
          <StyledThresholdValuesWrapper>
            <StyledTitleThreshold>Thresholds</StyledTitleThreshold>
            <StyledThresholdsDataWrapper>
              <ThresholdValues
                title="Warning:"
                executeFnTemp={formatInternalTemp}
                executeFnBuffer={formatUsedBuffer}
                warningHighLevelTemp={deviceData.internalTemp.warningHighLevel}
                warningHighLevelBuffer={deviceData.usedBufferObject.warningHighLevel}
                unitTemp={deviceData.internalTemp.unit}
                unitBuffer={deviceData.usedBufferObject.unit}
              />
              <ThresholdValues
                title="Alarm:"
                executeFnTemp={formatInternalTemp}
                executeFnBuffer={formatUsedBuffer}
                warningHighLevelTemp={deviceData.internalTemp.alarmHighLevel}
                warningHighLevelBuffer={deviceData.usedBufferObject.alarmHighLevel}
                unitTemp={deviceData.internalTemp.unit}
                unitBuffer={deviceData.usedBufferObject.unit}
              />
            </StyledThresholdsDataWrapper>
          </StyledThresholdValuesWrapper>
        </StyledThresholdWrapper>
      </StyledData>
    </Flexbox>
  )
}
export default DeviceDataSection
