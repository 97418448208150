import { ChannelInterface } from '@/models/devicesAndSensors/types'
import useGetCharactersLength from '@/modules/devicesSensors/hooks/useGetCharactersLength'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface ChannelAssetsCellProps {
  assets: ChannelInterface['uniquePlaces']
}

const ChannelAssetsCell: FC<ChannelAssetsCellProps> = ({ assets }) => {
  const characterLengths = useGetCharactersLength()

  return (
    <Flexbox feFlexDirection="column" data-testid={dataTestId.channelAssetsCell}>
      {assets.length > 0
        ? assets.map((item) => (
            <TextEllipsis
              type={TextEllipsisType.SingleLine}
              characterLength={characterLengths.assetName}
              value={item.asset.name}
              key={item.asset.name}
            />
          ))
        : '-'}
    </Flexbox>
  )
}

export default ChannelAssetsCell
