import {
  ChannelFaultEnum,
  ChannelInterface,
  ChannelStatusEnum,
  DeviceFaultEnum,
  DeviceInterface,
  DeviceModel,
  DeviceModelEnum,
  DeviceStatusEnum,
} from '@/models/devicesAndSensors/types'
import { formatFilters } from '@/modules/devices-sensors/utils'
import { deviceNumberFormatter } from '@/modules/devices-sensors/utils/constants'
import { roundTempValue } from '@/modules/devices-sensors/utils/tableUtils'
import {
  channelFaultText,
  channelStatusString,
  deviceFaultText,
  deviceStatusString,
} from '@/modules/devicesSensors/utils/constants'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { formatProp, roundTo } from '@/shared/utils'
import { MRT_ColumnFiltersState } from 'material-react-table'

/**
 * Formats the channel status and faults into a readable string.
 *
 * @param {ChannelStatusEnum} status - The status of the channel.
 * @param {ChannelInterface['faults']} faults - The list of faults associated with the channel.
 * @returns {string} A formatted string representing the channel status and its faults.
 */
const formatStatusAndFaults = <
  S extends ChannelStatusEnum | DeviceStatusEnum,
  F extends ChannelFaultEnum | DeviceFaultEnum,
>(
  status: S,
  faults: { fault: F }[],
  statusStringMap: Record<S, string>,
  faultTextMap: Record<F, string>
): string => {
  let text = `${statusStringMap[status]}`

  if (status !== 'normal') {
    text = `${text} ( ${faults.map((v) => faultTextMap[v.fault]).join('|')} )`
  }

  return text
}

/**
 * Formats the channel status and faults.
 *
 * This function takes a channel status and a list of faults, and formats them
 * using the provided formatting functions.
 *
 * @param {ChannelStatusEnum} status - The status of the channel.
 * @param {ChannelInterface['faults']} faults - The list of faults associated with the channel.
 * @returns {FormattedStatusAndFaults} The formatted status and faults.
 */
const formatChannelStatusAndFaults = (status: ChannelStatusEnum, faults: ChannelInterface['faults']) => {
  return formatStatusAndFaults(status, faults, channelStatusString, channelFaultText)
}

/**
 * Formats the device status and faults.
 *
 * @param {DeviceStatusEnum} status - The status of the device.
 * @param {DeviceInterface['faults']} faults - The faults associated with the device.
 * @returns {ReturnType<typeof formatStatusAndFaults>} The formatted status and faults.
 */
const formatDeviceStatusAndFaults = (status: DeviceStatusEnum, faults: DeviceInterface['faults']) => {
  return formatStatusAndFaults(status, faults, deviceStatusString, deviceFaultText)
}

/**
 * Formats the internal temperature value with the specified unit.
 *
 * @param internalTemp - The internal temperature value as a string or null.
 * @param unit - The unit of the temperature (e.g., "C" for Celsius, "F" for Fahrenheit).
 * @returns The formatted temperature string with the unit, or '-' if the internal temperature is null.
 */
const formatInternalTemp = (internalTemp: string | null, unit: string): string => {
  if (internalTemp !== null)
    return `${formatProp(internalTemp, (prop) => roundTempValue(parseFloat(prop)))} ${formatProp(unit, (prop) => prop)}`
  else return '-'
}

/**
 * Formats the used buffer value with the specified unit.
 *
 * @param {string | null} usedBuffer - The used buffer value to format. If null, returns '-'.
 * @param {string} unit - The unit to append to the formatted used buffer value.
 * @returns {string} The formatted used buffer value with the unit, or '-' if the used buffer is null.
 */
const formatUsedBuffer = (usedBuffer: string | null, unit: string): string => {
  if (usedBuffer !== null)
    return `${formatProp(usedBuffer, (prop) => roundTo(parseFloat(prop), 1).toString())} ${formatProp(unit, (prop) => prop)}`
  else return '-'
}

/**
 * Exports devices and sensors data to a CSV formatted string.
 *
 * @param {DeviceInterface[]} data - An array of device objects containing sensor data.
 * @param {string} customerName - The name of the customer for whom the data is being exported.
 * @param {MRT_ColumnFiltersState} filters - The filters applied to the data.
 * @returns {string} - A CSV formatted string representing the devices and sensors data.
 */
const exportDevicesAndSensorsDataToCSV = (
  data: DeviceInterface[],
  customerName: string,
  filters: MRT_ColumnFiltersState
): string => {
  const formattedFilters = formatFilters(filters)

  return [
    ['Site Name:', customerName].join(','),
    ['Dashboard:', 'Device & Sensor Management'].join(','),
    ['Export date:', formatDate(new Date(), DateFormats.AmericanDateTimeFormat)].join(','),
    [],
    [],
    [],
    [
      'Filters Applied:',
      '-',
      formattedFilters.name,
      '-',
      formattedFilters.status,
      formattedFilters.lastCommunicationDate,
      '-',
      '-',
      '-',
      '-',
      '-',
      '-',
    ].join(','),
    [
      'Device / Channel number',
      'Type',
      'Name',
      'Device',
      'Status',
      'Last Communication',
      'Device Firmware Version',
      'Device Internal Temperature',
      'Device used Buffer (MB)',
      'Sensor Voltage',
      'Sensor functional Location',
      'Sensor asset',
    ].join(','),
    data
      .map((device) => {
        const digitalChannelsData = device.channels
          .filter((c) => c.type === 'digital')
          .toSorted((a, b) => a.number - b.number) //sort alphabetically by type
          .map((c) => [
            `\t${deviceNumberFormatter.format(c.number)}`,
            'Sensor',
            c.name,
            device.deviceName,
            formatChannelStatusAndFaults(c.status, c.faults),
            (c.lastCommunicationTime && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
            '-',
            '-',
            '-',
            c.voltage || '-',
            (c.uniquePlaces &&
              c.uniquePlaces
                .map((item) => (item.functionalLocationName ? item.functionalLocationName : '-'))
                .join('; ')) ||
              '-',
            (c.uniquePlaces && c.uniquePlaces.map((item) => (item.asset.name ? item.asset.name : '-')).join('; ')) ||
              '-',
          ])
          .join('\n')

        const analogChannelsData = device.channels
          .filter((c) => c.type === 'analog')
          .toSorted((a, b) => a.number - b.number) //sort alphabetically by type
          .map((c) => [
            `\t${deviceNumberFormatter.format(c.number)}`,
            'Sensor',
            c.name,
            device.deviceName,
            formatChannelStatusAndFaults(c.status, c.faults),
            (c.lastCommunicationTime && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
            '-',
            '-',
            '-',
            c.voltage || '-',
            (c.uniquePlaces &&
              c.uniquePlaces
                .map((item) => (item.functionalLocationName ? item.functionalLocationName : '-'))
                .join('; ')) ||
              '-',
            (c.uniquePlaces && c.uniquePlaces.map((item) => (item.asset.name ? item.asset.name : '-')).join('; ')) ||
              '-',
          ])
          .join('\n')

        const deviceData = [
          `\t${deviceNumberFormatter.format(device.deviceNumber)}`,
          'Device',
          device.deviceName,
          '-',
          formatDeviceStatusAndFaults(device.status, device.faults),
          (device.lastCommunicationDate && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
          device.firmwareVersion || '-',
          formatInternalTemp(device.internalTemp.value, device.internalTemp.unit),
          formatUsedBuffer(device.usedBufferObject.value, device.usedBufferObject.unit),
          '-',
          '-',
          '-',
        ].join()

        const returnData = [deviceData]

        analogChannelsData.length > 0 && returnData.push(analogChannelsData)
        digitalChannelsData.length > 0 && returnData.push(digitalChannelsData)

        return returnData.join('\n')
      })
      .join('\n'),
  ].join('\n')
}

/**
 * Transforms a given device model enum value to a corresponding device model string.
 *
 * @param {DeviceModelEnum} deviceModel - The device model enum value to transform.
 * @returns {DeviceModel} The transformed device model string.
 */
const transformDeviceModelStatus = (deviceModel: DeviceModelEnum): DeviceModel => {
  let value = 'invalid'

  if (deviceModel === 'IMxSixteenPlus' || deviceModel === 'IMxSixteenW') {
    value = 'IMX-16'
  } else if (deviceModel === 'IMxEight') {
    value = 'IMX-8'
  }

  return value as DeviceModel
}

/**
 * Checks if there is any fault with the type `NoMeasurementEver` in the provided faults array.
 *
 * @param {ChannelInterface['faults']} faults - An array of fault objects to check.
 * @returns {boolean} - Returns `true` if there is at least one fault with the type `NoMeasurementEver`, otherwise `false`.
 */
const hasNoMeasurementEverData = (faults: ChannelInterface['faults']): boolean => {
  return faults.some((f) => f.fault === ChannelFaultEnum.NoMeasurementEver)
}
export { hasNoMeasurementEverData, exportDevicesAndSensorsDataToCSV, transformDeviceModelStatus }
