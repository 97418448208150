import styled from '@emotion/styled'
import { SPACINGS } from '@skf-internal/ui-components-react'
import { workOrderStatuses } from '@/modules/dashboard/utils/constants'
import { statusColor } from '@/modules/dashboard/utils/recommended-actions'
import { Boldness } from '@/shared/constants'

export const StyledRAWidgetTable = styled.table`
  width: 98%;
  table-layout: fixed;
  font-weight: ${Boldness.Bold};
  font-size: 14px;
  text-align: center;
  padding-left: 21px;
  padding-right: 8px;
  border-spacing: 0;

  & th {
    width: 60px;
  }

  & td {
    padding-top: 15px;
    padding-bottom: 10px;
  }
`
export const StyledRAWidgetContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACINGS.md};
`

export const StyledRAWidgetContent = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 4fr 2fr;
`

export const StyledRANoDataContainer = styled.div`
  display: flex;
  height: 50%;
  background-color: #f6f6f6;
  justify-content: center;
  font-weight: ${Boldness.Bold};
  font-size: 14px;
  color: #aaafb8;
  margin-left: 78px;
  margin-right: 24px;
  padding-top: 40px;
`

export const StyledRATendViewContainer = styled.div`
  display: flex;
  width: 50.9rem;
  height: 19.5rem;
`
export const StyledRATendViewContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

export const StyledRACurrentMonthStatusContainer = styled.div`
  padding: 2rem;
  background-color: #e6eefb;
  height: 17.2rem;
  width: 24.5rem;
  margin-left: 1rem;
`

const graphTitleHeight = SPACINGS.md
const graphHeight = SPACINGS.xxl
const spacing = SPACINGS.xs

export const StyledRACurrentMonthStatusContent = styled.div`
  display: grid;
  column-gap: ${SPACINGS.xs};
  grid-template-columns: 1fr 3fr;
  grid-template-rows:
    repeat(${workOrderStatuses.length - 1}, ${graphTitleHeight} ${graphHeight} ${spacing})
    ${graphTitleHeight} ${graphHeight};
`

export const StyledRAContentRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  column-gap: ${SPACINGS.xs};
`
export const StyledRACurrentMonthStatusIcons = styled.span<{ name: string }>`
  width: 12px;
  height: 12px;
  background-color: ${(props) => statusColor(props.name as string)};
`
export const StyledRAContentStatusRow = styled.div`
  font-size: 14px;
  font-weight: bolder;
`
export const StyledRAContentStatusRowName = styled.div`
  font-size: 10px;
  font-weight: bold;
`

export const StyledRAPieChartContainer = styled.div`
  width: 100%;
  height: 210px;
`
export const StyledRecommendedActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledDueDatesStatusWrapper = styled.div`
  padding: 1rem;
  background-color: #e6eefb;
  height: 19.2rem;
  width: 24.5rem;
`

export const StyledDueDatesStatusTitle = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 1.5rem;
`

export const StyledDueDatesStatusLabels = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
  font-weight: 600;
`
