import { ClosedRecommendedActionState } from '@/models/unresolvedRecommendations/types'
import { SortingFn, SortingFnType } from '@/shared/components/TableComponents/sortingFunction/types'
import { compareDates } from '@/shared/dateUtils'
import { Row } from '@tanstack/react-table'
import { MRT_Row, MRT_RowData } from 'material-react-table'

/**
 * Creates a Map representing the sort order for table columns.

 * @param {string[]} sortOrder - An array of strings representing the desired sort order of table columns.
 * @returns {Map<string, number>} A newly created Map where keys are column names from the sort order array and values are their corresponding indices.

 * @description
 * This function takes an array of strings representing the desired sort order of table columns and transforms it into a `Map` for efficient lookup. Each key in the `Map` is a column name from the `sortOrder` array, and its corresponding value is the index of that column within the array.
*/
const createTableSortingOrderMap = (sortOrder: string[]) => new Map(sortOrder.map((value, index) => [value, index]))

/**
 * Compares two dates and returns a value indicating their relative order.

 * @template T - The type of the row data.
 * @param {Row<T>} rowA - The first row to compare.
 * @param {Row<T>} rowB - The second row to compare.
 * @param {string} columnId - The ID of the column to compare.
 * @returns {number} A negative number if `rowA` is before `rowB`, a positive number if `rowA` is after `rowB`, or 0 if they are equal.

 * @description
 * This function compares two rows based on the specified column's date values. It handles various scenarios, including:
 * - **Equal dates:** Returns 0.
 * - **"no date available"** values: Prioritizes rows with valid dates.
 * - **"-" values:** Moves rows with "-" to the end.
 * - **Date comparison:** Uses `dayjs` library to compare dates and returns a difference in days.
 */
const datetimeSortingFn = <T extends MRT_RowData>(
  rowA: Row<T> | MRT_Row<T>,
  rowB: Row<T> | MRT_Row<T>,
  columnId: string
): number => {
  const valueA = rowA.getValue(columnId) as string
  const valueB = rowB.getValue(columnId) as string

  // Early return for identical values
  if (valueA === valueB) return 0

  // Handle 'no date available' cases
  if (valueA === 'no date available') return -1
  if (valueB === 'no date available') return 1

  // Handle '-' cases
  if (valueA === '-') return 1
  if (valueB === '-') return -1

  return compareDates(valueA, valueB)
}

/**
 * Compares two rows based on their status values, using a specified sort order.

 * @template T - The type of the row data.
 * @param {Row<T>} rowA - The first row to compare.
 * @param {Row<T>} rowB - The second row to compare.
 * @param {string} columnId - The ID of the column containing the status values.
 * @param {Map<string, number>} sortOrder - An optional map specifying the desired sort order of status values.
 * @returns {number} A negative number if `rowA` should be sorted before `rowB`, a positive number if `rowA` should be sorted after `rowB`, or 0 if they are equal.

 * @description
 * This function compares the `status` values of two rows based on a specified sort order. It first checks if the values are equal. If not, it uses the `sortOrder` map to determine the relative order of the values. If a value is not found in the `sortOrder` map, it's assigned the lowest priority.

 * **Key Points:**
 * - **Efficient Comparison:** Uses a `Map` for efficient lookup of status values and their corresponding sort order.
 * - **Flexible Sorting:** Allows for custom sorting orders by providing a `sortOrder` map.
 * - **Handles Missing Values:** Assigns a default low priority to values not found in the `sortOrder` map.
 */
const statusSortingFn = <T extends MRT_RowData>(
  rowA: Row<T> | MRT_Row<T>,
  rowB: Row<T> | MRT_Row<T>,
  columnId: string,
  sortOrder?: Map<string, number>
): number => {
  const valueA = rowA.getValue<string>(columnId).toLocaleLowerCase()
  const valueB = rowB.getValue<string>(columnId).toLocaleLowerCase()

  if (valueA === valueB) {
    return 0
  }

  const aIndex = sortOrder?.get(valueA) ?? -1
  const bIndex = sortOrder?.get(valueB) ?? -1

  return aIndex - bIndex
}

/**
 * Compares two rows based on their boolean values.

 * @template T - The type of the row data.
 * @param {Row<T>} rowA - The first row to compare.
 * @param {Row<T>} rowB - The second row to compare.
 * @param {string} columnId - The ID of the column containing the boolean values.
 * @returns {number} A negative number if `rowA` should be sorted before `rowB`, a positive number if `rowA` should be sorted after `rowB`, or 0 if they are equal.

 * @description
 * This function compares the boolean values of two rows and returns a number indicating their relative order. It prioritizes `true` values over `false` values.
*/
const booleanSortingFn = <T extends MRT_RowData>(
  rowA: Row<T> | MRT_Row<T>,
  rowB: Row<T> | MRT_Row<T>,
  columnId: string
): number => {
  const aBool = rowA.getValue<boolean>(columnId)
  const bBool = rowB.getValue<boolean>(columnId)

  if (aBool === bBool) {
    return 0
  }

  return aBool ? 1 : -1
}

/**
 * Compares two rows based on their numeric IDs, prioritizing a specific ID value.

 * @template T - The type of the row data.
 * @param {Row<T>} rowA - The first row to compare.
 * @param {Row<T>} rowB - The second row to compare.
 * @returns {number} A negative number if `rowA` should be sorted before `rowB`, a positive number if `rowA` should be sorted after `rowB`, or 0 if they are equal.

 * @description
 * This function compares the numeric IDs of two rows. It handles special cases where one or both IDs might be a specific value (`ClosedRecommendedActionState.dueInDays`). If both IDs are equal or one of them is the special value, the function returns 0. Otherwise, it parses the IDs as numbers and returns their difference to determine the sort order.
*/
const numberSortingFn = <T extends MRT_RowData>(
  rowA: Row<T> | MRT_Row<T>,
  rowB: Row<T> | MRT_Row<T>,
  columnId: string
): number => {
  const rowA_ID = rowA.getValue<number>(columnId)
  const rowB_ID = rowB.getValue<number>(columnId)

  if (!rowA_ID || rowA_ID === rowB_ID) {
    return 0
  }
  // Handle special cases
  if (
    rowA_ID.toString() === ClosedRecommendedActionState.dueInDays ||
    rowB_ID.toString() === ClosedRecommendedActionState.dueInDays
  ) {
    return 0
  }

  // Parse IDs into integers
  const diffA = Number(rowA_ID)
  const diffB = Number(rowB_ID)

  // Return difference only if parsing is successful
  return !isNaN(diffB) && !isNaN(diffA) ? diffB - diffA : 0
}

const sortingRegistry: Record<SortingFnType, SortingFn> = {
  date: datetimeSortingFn,
  status: statusSortingFn,
  boolean: booleanSortingFn,
  number: numberSortingFn,
}

export { sortingRegistry, createTableSortingOrderMap }
