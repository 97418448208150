import React from 'react'
import ReportFilters from '@/modules/report-generator/components/reportFilters'
import {
  StyledGenerateReportContainer,
  StyledGenerateReportFilterWrap,
  StyledGenerateReportFooter,
  StyledReportFilters,
} from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'
import ReportGeneratorHeader from '../header'
import { Button } from '@skf-internal/ui-components-react'

const GenerateReport: React.FC = () => {
  return (
    <StyledGenerateReportContainer data-testid={dataTestId.generateReport}>
      <StyledGenerateReportFilterWrap>
        <ReportGeneratorHeader />
        <StyledReportFilters>
          <ReportFilters />
        </StyledReportFilters>
      </StyledGenerateReportFilterWrap>
      <StyledGenerateReportFooter>
        <Button data-testid={dataTestId.generateReportExportButton} feType="secondary" feSize="sm" disabled>
          Export
        </Button>
      </StyledGenerateReportFooter>
    </StyledGenerateReportContainer>
  )
}
export default GenerateReport
