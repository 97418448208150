import { IReportSelect } from '@/models/reportGenerator/types'
import { Select, SelectItem } from '@skf-internal/ui-components-react'
import { SelectValueTypeConstraint } from '@skf-internal/ui-components-react/dist/components/select/selectCommonTypes'
import { useCallback, useMemo } from 'react'
import { StyledReportSelectWrapper } from '../../styled'
import { dataTestId } from '@/tests/testid'

interface ReportSelectProps<T extends SelectValueTypeConstraint, R> extends IReportSelect<R> {
  items: SelectItem<T>[]
  label: string
  transformPassedValueFn?: (value: R[]) => T
  transformSelectedValueFn?: (value: T) => R[]
  multiple: boolean
  allValues?: string[]
}

const ReportSelect = <T extends SelectValueTypeConstraint, R>({
  value,
  onChange,
  items,
  label,
  transformPassedValueFn,
  transformSelectedValueFn,
  multiple = false,
  allValues = [],
}: ReportSelectProps<T, R>) => {
  // Memoized single select value transformation
  const singleValue = useMemo(() => {
    if (!multiple && transformPassedValueFn) {
      return transformPassedValueFn(value) // Transform external value for single select
    }
    return undefined
  }, [value, transformPassedValueFn, multiple])

  // Multi-select change handler
  const handleMultiChange = useCallback(
    (selectedValues: T[]) => {
      onChange(selectedValues as unknown as R[]) // Forward multi-selected values
    },
    [onChange]
  )

  // Single-select change handler
  const handleSingleChange = useCallback(
    (selectedValue: T) => {
      transformSelectedValueFn && onChange(transformSelectedValueFn(selectedValue)) // Transform and forward the selected value
    },
    [onChange, transformSelectedValueFn]
  )

  // Render the `Select` component based on `multiple` mode
  return (
    <StyledReportSelectWrapper>
      {multiple ? (
        <Select<T>
          data-testid={dataTestId.reportGeneratorCommonSelect}
          feLabel={label}
          feItems={items}
          feSize="sm"
          multiple={true}
          value={value as unknown as T[]}
          onChange={handleMultiChange}
          feHideSelectionTags={true}
          feMultiSelectionText={value.length === allValues.length ? 'All' : `${value.length} Selected`}
        />
      ) : (
        <Select<T>
          data-testid={dataTestId.reportGeneratorCommonSelect}
          feLabel={label}
          feItems={items}
          feSize="sm"
          multiple={false}
          value={singleValue as T} // Memoized transformed value
          onChange={handleSingleChange}
        />
      )}
    </StyledReportSelectWrapper>
  )
}

export default ReportSelect
