import { ColumnFiltersState, ExpandedState, SortingState } from '@tanstack/react-table'

//================ API RESPONSE TYPES========================
export enum DeviceModelEnum {
  IMxSixteenPlus = 'IMxSixteenPlus',
  IMxSixteenW = 'IMxSixteenW',
  IMxEight = 'IMxEight',
  IMxS = 'IMxS',
  IMxW = 'IMxW',
  IMxP = 'IMxP',
  IMxT = 'IMxT',
  IMxR = 'IMxR',
  IMxKnorr = 'IMxKnorr',
  IMxC = 'IMxC',
  IMxB = 'IMxB',
  IMxRail = 'IMxRail',
}

export enum DeviceStatusEnum {
  Normal = 'normal',
  NotNormal = 'notNormal',
}

export enum DeviceFaultEnum {
  Buffer = 'buffer',
  CableFault = 'cable_fault',
  NoMeasurement24h = 'no_measurement_24h',
  InternalTemperature = 'internal_temperature',
  Invalid = 'invalid',
  ChannelFault = 'channel_fault',
  BrokenConnection = 'broken_connection',
}

export enum ChannelStatusEnum {
  Normal = 'normal',
  NotNormal = 'notNormal',
  ChannelNotConnected = 'channelNotConnected',
  NoSensorData = 'noSensorData',
}

export enum ChannelTypeEnum {
  Digital = 'digital',
  Analog = 'analog',
}

export enum ChannelFaultEnum {
  CableFault = 'cable_fault',
  NoMeasurement24h = 'no_measurement_24h',
  NoMeasurementEver = 'no_measurement_ever',
  BrokenConnection = 'broken_connection',
  Invalid = 'invalid',
}

type ChannelFaultType = {
  fault: ChannelFaultEnum
}

type DeviceFaultType = {
  fault: DeviceFaultEnum
}

type SensorInterface = {
  sensorNumber: number
  sensorName: string
  type: ChannelTypeEnum
  status: ChannelStatusEnum
  lastCommunicationDate?: string | undefined
  functionalLocationName?: string | undefined
  voltage?: number | undefined
  asset?:
    | {
        name: string
        id: string
      }
    | undefined
  faults: ChannelFaultType[]
}

export type ChannelInterface = {
  number: number
  name: string
  type: ChannelTypeEnum
  status: ChannelStatusEnum
  lastCommunicationTime?: string | undefined
  voltage?: number | undefined
  faults: ChannelFaultType[]
  uniquePlaces: UniquePlaces[]
}

type UniquePlaces = {
  functionalLocationName: string
  asset: {
    name: string
    ID: string
  }
}

type DeviceAttributeInterface = {
  unit: string
  value: string
  alarmHighLevel: string
  warningHighLevel: string
}

export type DeviceInterface = {
  deviceNumber: number
  deviceID: string
  deviceName: string
  model: DeviceModelEnum
  status: DeviceStatusEnum
  firmwareVersion?: string | undefined
  lastCommunicationDate?: string | undefined
  internalTemp: DeviceAttributeInterface
  usedBufferObject: DeviceAttributeInterface
  faults: DeviceFaultType[]
  sensors: SensorInterface[]
  channels: ChannelInterface[]
  uniqueChannelsAssets: UniqueChannelsAssets[]
}

export type DevicesAndSensorsData = {
  devices: DeviceInterface[]
}

//==================APP MAPPING TYPES =============================================

export type DeviceModel = 'IMX-8' | 'IMX-16' | 'invalid'
export type DeviceStatus = 'normal' | 'notNormal'
type DeviceFault =
  | 'buffer' //
  | 'channel_fault' //
  | 'cable_fault'
  | 'broken_connection'
  | 'no_measurement_24h' //
  | 'internal_temperature' //
export type ChannelStatus = 'normal' | 'notNormal' | 'noSensorData' | 'channelNotConnected'
type ChannelType = 'analog' | 'digital'
type ChannelFault = 'cable_fault' | 'broken_connection' | 'no_measurement_24h' | 'no_measurement_ever'

export type Channel = {
  number: number
  name: string
  status: ChannelStatus
  type: ChannelType | 'invalid'
  lastCommunicationDate?: string | 'invalid'
  voltage?: number
  asset: UniquePlaces[]
  faults: (ChannelFault | 'invalid')[]
}

export type DeviceAttribute = {
  unit: string
  value: string
  alarmHighLevel: string
  warningHighLevel: string
}

export type Device = {
  id: string
  number: number
  name: string
  model: DeviceModel
  status: DeviceStatus
  firmwareVersion?: string
  lastCommunicationDate?: string | 'invalid'
  internalTemp: DeviceAttribute
  usedBuffer: DeviceAttribute
  faults: (DeviceFault | 'invalid')[]
  channels: Channels
  uniqueChannelsAssets: UniqueChannelsAssets[]
}

export type UniqueChannelsAssets = {
  name: string
  ID: string
}

export type Channels = Channel[]
export type Devices = Device[]

//=========================== TABLE ROW UNITED TYPES =================================

export type DevicesSensorsTableChannelRowData = Pick<
  Channel,
  'number' | 'name' | 'status' | 'lastCommunicationDate' | 'type' | 'voltage' | 'faults' | 'asset'
>

export type DevicesSensorsTableDeviceRowData = Pick<
  Device,
  | 'id'
  | 'number'
  | 'name'
  | 'status'
  | 'lastCommunicationDate'
  | 'faults'
  | 'firmwareVersion'
  | 'internalTemp'
  | 'usedBuffer'
  | 'model'
  | 'uniqueChannelsAssets'
> & {
  channels: DevicesSensorsTableChannelRowData[]
}
//======================Side Menu Types===========

export type DevicesSensorsSideMenuChannelData = Pick<
  Channel,
  'name' | 'status' | 'voltage' | 'asset' | 'faults' | 'lastCommunicationDate'
>

export type DevicesSensorsSideMenuDeviceData = Pick<
  Device,
  'name' | 'status' | 'firmwareVersion' | 'lastCommunicationDate' | 'internalTemp' | 'usedBuffer' | 'faults'
>

export type DevicesAndSensorsSelectedItemsData = {
  device?: DevicesSensorsTableDeviceRowData
  channel?: DevicesSensorsTableChannelRowData
  channelIndex?: number
}

export type DevicesAndSensorsSelectedItemsDataNew = {
  device?: DeviceInterface
  channel?: ChannelInterface
  channelIndex?: number
}

export type DevicesAndSensorsTableData = {
  sorting?: SortingState
  columnFilters?: ColumnFiltersState
  expanded?: ExpandedState
}

export type ColumnId = 'number' | 'name' | 'status' | 'lastCommunicationDate' | 'expandRow' | 'uniqueChannelsAssets'
