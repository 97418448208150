import { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { BaseClient } from './BaseClient'
import { getBaseClientUrl, getAuthToken } from '@/api/baseClientUtils'

export class NetworkClient extends BaseClient {
  async injectHeaders(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    if (config.url && /.*\{.*\}.*/.test(config.url) && config.params) {
      config.url = Object.values(config.params).reduce((acc: string, value) => {
        return acc.replace(/\${(.*?)}/, value as string)
      }, config.url)
      config.params = undefined
    }

    config.baseURL = getBaseClientUrl(config.url)

    if (config.baseURL) {
      //In case of s3 bucket the base utl will be undefined and we do not need to add auth token
      config.headers.Authorization = await getAuthToken()
    } else {
      config.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate')
      config.headers.set('Pragma', 'no-cache')
      config.headers.set('Expires', 0)
    }

    return config
  }

  addInterceptors(axiosInstance: AxiosInstance): void {
    super.addInterceptors(axiosInstance)
    axiosInstance.interceptors.request.use(this.injectHeaders, (error: AxiosError) => Promise.reject(error))
  }
}

const networkClient = new NetworkClient()

export default networkClient
