import useGetCharactersLength from '@/modules/devicesSensors/hooks/useGetCharactersLength'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { formatProp } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'

interface LastCommunicationCellProps {
  lastCommunicationDate: string | undefined
}

const LastCommunicationCell: FC<LastCommunicationCellProps> = ({ lastCommunicationDate }) => {
  const characterLengths = useGetCharactersLength()
  return (
    <TextEllipsis
      data-testid={dataTestId.lastCommunicationCell}
      type={TextEllipsisType.SingleLine}
      characterLength={characterLengths.lastCommunicationDate}
      value={formatProp(lastCommunicationDate, (prop) => formatDate(prop, DateFormats.AmericanDateTimeFormat))}
    />
  )
}

export default LastCommunicationCell
