/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import { DeviceInterface, DeviceStatusEnum, UniqueChannelsAssets } from '@/models/devicesAndSensors/types'
import LastCommunicationCell from '@/modules/devicesSensors/components/commonCells/lastCommunicationCell'
import StatusCell from '@/modules/devicesSensors/components/commonCells/statusCell'
import DeviceConnectedAssetsCell from '@/modules/devicesSensors/components/deviceCells/deviceConnectedAssetsCell'
import DeviceNumberCell from '@/modules/devicesSensors/components/deviceCells/deviceNumberCell'
import TableColumn from '@/modules/devicesSensors/components/tableColumn'
import useGetCharactersLength from '@/modules/devicesSensors/hooks/useGetCharactersLength'
import useGetHeaderCharactersLength from '@/modules/devicesSensors/hooks/useGetHeaderCharactersLength'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/TableComponents/sortingFunction'
import { devicesAndSensorsStatusSortingOrder } from '@/shared/components/TableComponents/sortingFunction/constants'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'

const useDevicesColumnsDefinition = () => {
  const characterLengths = useGetCharactersLength()
  const columnHeaderCharacterLength = useGetHeaderCharactersLength()

  const columns = useDeepCompareMemo<MRT_ColumnDef<DeviceInterface>[]>(
    () => [
      {
        accessorKey: 'deviceNumber',
        id: 'deviceNumber',
        header: '',
        size: 60,
        enableColumnFilter: false,
        Cell: ({ cell }) => <DeviceNumberCell deviceNumber={cell.getValue<number>()} />,
      },
      {
        accessorKey: 'deviceName',
        header: 'Device',
        Header: ({ column }) => <TableColumn column={column} characterLengths={columnHeaderCharacterLength.name} />,
        size: 150,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        Filter: () => <>{filterRegistry.text.FilterComponent}</>,
        meta: {
          filterType: 'text',
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 100,
        Header: ({ column }) => <TableColumn column={column} characterLengths={columnHeaderCharacterLength.status} />,
        Cell: ({ cell }) => <StatusCell sensorStatus={cell.getValue<DeviceStatusEnum>()} />,
        //@ts-ignore
        filterFn: filterRegistry.device_status.filterFn,
        Filter: () => <>{filterRegistry.device_status.FilterComponent}</>,
        meta: {
          filterType: 'device_status',
        },
        sortingFn: (rowA, rowB, columnId: string) =>
          sortingRegistry.status<DeviceInterface>(
            rowA as MRT_Row<DeviceInterface>,
            rowB as MRT_Row<DeviceInterface>,
            columnId,
            createTableSortingOrderMap(devicesAndSensorsStatusSortingOrder)
          ),
      },
      {
        accessorKey: 'lastCommunicationDate',
        header: 'Last Communication',
        Header: ({ column }) => (
          <TableColumn column={column} characterLengths={columnHeaderCharacterLength.lastCommunicationDate} />
        ),
        size: 180,
        Cell: ({ cell }) => <LastCommunicationCell lastCommunicationDate={cell.getValue<string | undefined>()} />,
        //@ts-ignore
        filterFn: filterRegistry.date.filterFn,
        Filter: () => <>{filterRegistry.date.FilterComponent}</>,
        meta: {
          filterType: 'date',
        },
      },
      {
        accessorKey: 'uniqueChannelsAssets',
        header: 'Connected Assets',
        Header: ({ column }) => (
          <TableColumn column={column} characterLengths={columnHeaderCharacterLength.assetName} />
        ),
        size: 160,
        Cell: ({ cell }) => <DeviceConnectedAssetsCell connectedAssets={cell.getValue<UniqueChannelsAssets[]>()} />,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        Filter: () => <>{filterRegistry.text.FilterComponent}</>,
        meta: {
          filterType: 'text',
        },
      },
    ],
    [characterLengths]
  )

  return columns
}

export default useDevicesColumnsDefinition
