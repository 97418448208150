import { DateFilter, MappingData, RecommendedAction } from '@/models/unresolvedRecommendations/types'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { capitalizeFirstLetter, formatProp } from '@/shared/utils'
import { ColumnFiltersState, Row } from '@tanstack/react-table'

const exportUnresolvedRecommendationsDataToCSV = (
  data: RecommendedAction[],
  customerName: string,
  filters: ColumnFiltersState
): string => {
  const appliedFilters = formatFilters(filters)

  // Format asset status for filters row
  const formattedAssetStatus = formatAssetStatus(appliedFilters['assetStatus'])

  return [
    `Site Name:,${customerName}`,
    'Dashboard:, Open Recommendations',
    `Export date:, ${formatDate(new Date(), DateFormats.AmericanDateTimeFormat)}`,
    [],
    [],
    [],
    // Filters applied
    [
      'Filters Applied:',
      appliedFilters['assetName'] || 'none',
      appliedFilters['assetParent'] || 'none',
      formattedAssetStatus,
      appliedFilters['faultType'] || 'none',
      'none',
      appliedFilters['observation'] || 'none',
      appliedFilters['recommendedAction'] || 'none',
      'none',
      appliedFilters['raOpenDate'] || 'none',
      appliedFilters['raDueDate'] || 'none',
      appliedFilters['raDaysToDue'] || 'none',
    ].join(','),
    // table header row
    [
      '#',
      'Asset Name',
      'Belongs to',
      'Asset Status',
      'Fault',
      'Fault ID',
      'Observation',
      'Recommended Action',
      'Additional Comments',
      'RA Created Date',
      'RA Due Date',
      'Days to Due',
    ].join(','),
    // rows data
    ...data.map((recommendation, index) =>
      [
        recommendation.raOutcome ? 'Closed' : index + 1,
        formatCsvValues(recommendation.assetName),
        formatCsvValues(recommendation.assetParent),
        formatCsvValues(recommendation.assetStatus),
        formatCsvValues(recommendation.faultType),
        formatCsvValues(recommendation.faultId),
        formatCsvValues(recommendation.observation),
        `"${formatProp(recommendation.recommendedAction, (prop) => prop)}"`,
        `"${formatProp(recommendation.raComment, (prop) => prop)}"`,
        formatProp(recommendation.raOpenDate, (prop) => prop),
        formatProp(recommendation.raDueDate, (prop) => prop),
        formatProp(recommendation.raDaysToDue, (prop) => prop.toString()),
      ].join(',')
    ),
  ].join('\n')
}

const formatAssetStatus = (assetStatus: string | undefined): string => {
  return assetStatus
    ? assetStatus
        .split(',')
        .map((status) => status.trim())
        .join(', ')
    : 'none'
}

const formatFilters = (filters: ColumnFiltersState): Record<string, string> => {
  const result: Record<string, string> = {}

  filters.forEach((filter) => {
    switch (filter.id) {
      case 'assetStatus':
        result['assetStatus'] = Array.isArray(filter.value)
          ? filter.value.reduce((result, value) => (result += capitalizeFirstLetter(value) + '.'), '')
          : String(filter.value)
        break
      case 'assetName':
        result['assetName'] = formatCsvValues(String(filter.value))
        break
      case 'assetParent':
        result['assetParent'] = formatCsvValues(String(filter.value))
        break
      case 'faultType':
        result['faultType'] = formatCsvValues(String(filter.value))
        break
      case 'recommendedAction':
        result['recommendedAction'] = formatCsvValues(String(filter.value))
        break
      case 'observation':
        result['observation'] = formatCsvValues(String(filter.value))
        break
      case 'raOpenDate':
      case 'raDueDate':
        if (typeof filter.value === 'object') {
          result[filter.id] = formatDateFilter(filter.value as DateFilter)
        }
        break
      case 'raDaysToDue':
        result['raDaysToDue'] = formatCsvValues(String(filter.value))
        break
      default:
        break
    }
  })

  return result
}

const formatDateFilter = (value: DateFilter): string => {
  if (!value || typeof value !== 'object') return 'none'

  const { dateFilterType, startDate, endDate } = value

  switch (dateFilterType) {
    case 'after':
      return `After: ${startDate?.toString() ?? 'none'}`
    case 'before':
      return `Before: ${startDate?.toString() ?? 'none'}`
    case 'between':
      return `Start Date: ${startDate?.toString() ?? 'none'}.End Date: ${endDate?.toString() ?? 'none'}`
    default:
      return 'none'
  }
}

const formatCsvValues = (data: string): string => {
  if (data.includes(',') || data.includes('\n')) {
    return formatProp(`"${data.replace(/"/g, '""').replace(/\n/g, ' ')}"`, (prop) => prop)
  }
  return formatProp(data, (prop) => prop)
}

const getCSVFilename = (): string => {
  const timestamp = new Date().getTime()
  return `open-recommendations-${timestamp}.csv`
}

/**
 * Maps fault types for filtered rows of recommended actions.
 *
 * @param {Row<RecommendedAction>[]} filteredRows - Array of recommended action rows
 * @param {MappingData} faultTypes - Mapping data for fault types
 * @returns {Row<RecommendedAction>[]} Updated rows with mapped fault types
 *
 * @description
 * - If no fault types are provided, returns the original filtered rows
 * - Maps fault type for each row using the provided mapping data
 * - Preserves original row structure while updating the fault type
 */
const mapCSVFaultTypeRaData = (
  filteredRows: Row<RecommendedAction>[],
  faultTypes: MappingData
): Row<RecommendedAction>[] => {
  if (!faultTypes.length) return filteredRows

  return filteredRows.map((row) => ({
    ...row,
    original: {
      ...row.original,
      faultType: getMappedValue(faultTypes, row.original.faultType),
    },
  }))
}

/**
 * Retrieves the text value corresponding to a given code from a mapping array.
 *
 * @param {MappingData} data - An array of mapping objects containing code and text properties.
 * @param {string} value - The code to search for in the mapping array.
 *
 * @returns {string} The text value associated with the matching code, or an empty string if no match is found.
 *
 * @example
 * const mappingData = [
 *   { code: 'US', text: 'United States' },
 *   { code: 'CA', text: 'Canada' }
 * ];
 *
 * const result = getMappedValue(mappingData, 'US'); // Returns 'United States'
 * const noMatchResult = getMappedValue(mappingData, 'UK'); // Returns ''
 */
export const getMappedValue = (data: MappingData, value: string): string => {
  const mappedData = data?.find(({ code }) => value === code)
  return mappedData ? mappedData.text : ''
}

export {
  exportUnresolvedRecommendationsDataToCSV,
  formatAssetStatus,
  formatCsvValues,
  formatDateFilter,
  formatFilters,
  getCSVFilename,
  mapCSVFaultTypeRaData,
}
