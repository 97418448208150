import { GetAnalysisBacklogAssetsResponse } from '@/api/paths/analysis/types'
import { AutomaticDiagnosticsData } from '@/models/automaticDiagnostics/types'
import { DevicesAndSensorsData } from '@/models/devicesAndSensors/types'
import { OverviewSummary } from '@/models/overview/types'
import { UnresolvedRecommendationData } from '@/models/unresolvedRecommendations/types'
import { AsyncStatus } from '@/shared/hooks/useAsync'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { UseApiProps } from '@/shared/hooks/useApi'
import { createContext, useMemo, useState } from 'react'
import { OrphanNotes } from '@/models/orphanNotes/types'
import { SiteDetailsData } from '@/models/siteDetails/types'

type FetchDataContextType = (
  props?: UseApiProps
) => () => Promise<
  | void
  | UnresolvedRecommendationData
  | GetAnalysisBacklogAssetsResponse
  | OrphanNotes
  | DevicesAndSensorsData
  | AutomaticDiagnosticsData
  | OverviewSummary
  | SiteDetailsData
>

interface IFetchDataContext<FetchDataContextType> {
  fetchDataFn: FetchDataContextType
  setFetchDataFn: React.Dispatch<IFetchDataContext<FetchDataContextType>['fetchDataFn']>
  fetchDataStatus: AsyncStatus
  setFetchDataStatus: React.Dispatch<React.SetStateAction<IFetchDataContext<FetchDataContextType>['fetchDataStatus']>>
}

const createGenericContext = <T,>() => {
  return createContext<IFetchDataContext<T> | null>(null)
}

const FetchDataContext = createGenericContext<FetchDataContextType>()

export const useFetchDataContext = () =>
  useContextWrapper(FetchDataContext, {
    contextName: useFetchDataContext.name,
    providerName: FetchDataContextProvider.name,
  })

export const FetchDataContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [fetchDataFn, setFetchDataFn] = useState<FetchDataContextType>(
    (async () => {}) as unknown as FetchDataContextType
  )

  const [fetchDataStatus, setFetchDataStatus] = useState<AsyncStatus>('not-executed')
  const value = useMemo(
    () => ({
      fetchDataFn,
      setFetchDataFn,
      fetchDataStatus,
      setFetchDataStatus,
    }),
    [fetchDataFn, fetchDataStatus]
  )

  return <FetchDataContext.Provider value={value}>{children}</FetchDataContext.Provider>
}
