import { StyledColoredSpan } from '@/modules/devicesSensors/styled'
import { FC } from 'react'

interface ColoredValueProps {
  executeFn: (val: string, unit: string) => string
  alarmHighLevel: string | null
  warningHighLevel: string | null
  value: string
  unit: string
}

const ColoredValue: FC<ColoredValueProps> = ({ executeFn, value, warningHighLevel, alarmHighLevel, unit }) => {
  return (
    <StyledColoredSpan value={value} alarm={alarmHighLevel} warning={warningHighLevel}>
      {executeFn(value, unit)}
    </StyledColoredSpan>
  )
}
export default ColoredValue
