import { FetchDataContextProvider, useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { DevicesAndSensorsContextProvider, useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import NewDevicesSensorsHeader from '@/modules/devicesSensors/components/header'
import { DevicesSensorsSideMenu } from '@/modules/devicesSensors/components/sideMenu'
import useNewGetDevicesAndSensorsData from '@/modules/devicesSensors/hooks/useNewGetDevicesAndSensorsData'
import { StyledDevicesAndSensorsModuleContainer } from '@/modules/devicesSensors/styled'
import useDevicesTable from '@/modules/devicesSensors/tables/useDevicesTable'
import { LoadingType } from '@/shared/hooks/useApi'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { MaterialReactTable } from 'material-react-table'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const DevicesAndSensors = () => {
  return (
    <DevicesAndSensorsContextProvider>
      <FetchDataContextProvider>
        <InnerDevicesAndSensors />
      </FetchDataContextProvider>
    </DevicesAndSensorsContextProvider>
  )
}

const InnerDevicesAndSensors = () => {
  const { sid } = useParams()

  const { execute, reset } = useNewGetDevicesAndSensorsData()

  const { setFetchDataFn } = useFetchDataContext()

  const { setDevicesAndSensorsSelectedItemsDataNew, devicesAndSensorsSelectedItemsDataNew } =
    useDevicesAndSensorsContext()

  useDeepCompareEffect(() => {
    if (sid) {
      reset()
      execute({ loaderType: LoadingType.NONE })
    }
  }, [sid])

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useCleanupStorage()

  useDeepCompareEffect(() => {
    table.resetSorting()
    table.resetColumnFilters()
    if (devicesAndSensorsSelectedItemsDataNew.device || devicesAndSensorsSelectedItemsDataNew.channel) {
      setDevicesAndSensorsSelectedItemsDataNew({ device: undefined, channel: undefined })
    }
  }, [sid])

  const table = useDevicesTable()

  return (
    <Flexbox feGap="sm" data-testid={dataTestId.devicesSensorsModule}>
      <StyledDevicesAndSensorsModuleContainer feFlexDirection="column">
        <NewDevicesSensorsHeader table={table} />
        <MaterialReactTable table={table} />
      </StyledDevicesAndSensorsModuleContainer>
      <DevicesSensorsSideMenu />
    </Flexbox>
  )
}

export default DevicesAndSensors
