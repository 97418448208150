import GenericLoader, { GenericLoaderType } from '@/shared/components/GenericLoader'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import BearingSpinner from '@/shared/components/BearingSpinner'
import HeaderClearFiltersButton from '@/shared/components/ModuleHeader/clearFiltersButton'
import HeaderExportCSVButton from '@/shared/components/ModuleHeader/exportButton'
import HeaderRefreshButton from '@/shared/components/ModuleHeader/refreshButton'
import HeaderTableStateChangeSwitch from '@/shared/components/ModuleHeader/stateChangeSwitch'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { StyledHeaderActionPanel } from '@/shared/components/ModuleHeader/styled'

/**
 * HeaderActionPanel is a component that renders action buttons and loaders in the header of a module.
 * It is configurable based on the presence of certain features like filters, table state changes,
 * refresh functionality, and CSV download options.
 *
 * This component uses Redux for state management and context for fetching data status.
 * It displays various action buttons based on the configuration retrieved from the Redux store.
 *
 * The following buttons may be displayed:
 * - Clear Filters Button: Allows users to clear applied filters.
 * - Table State Change Switch: A toggle to change the state of the table.
 * - Refresh Button: Refreshes the data displayed in the table.
 * - CSV Download Button: Enables users to download the current data in CSV format.
 *
 * Additionally, loaders and spinners are shown based on the fetch status of the data.
 *
 * @returns {JSX.Element} A rendered Flexbox component containing action buttons and loaders.
 */

const HeaderActionPanel = () => {
  const {
    moduleHeaderData: {
      headerActionPanelConfig: {
        withClearFiltersButton,
        withTableStateChangeSwitch,
        withRefreshButton,
        withCSVDownloadButton,
        isWithUserEvents,
      },
    },
  } = useTypedSelector((store) => ({ ...store }))
  const { fetchDataStatus } = useFetchDataContext()

  return (
    <StyledHeaderActionPanel
      feJustifyContent="space-evenly"
      feAlignItems="center"
      data-testid={dataTestId.headerActionPanel}
      isWithUserEvents={!!isWithUserEvents}
    >
      <BearingSpinner />
      <GenericLoader fetchDataStatus={fetchDataStatus} loaderType={GenericLoaderType.HEADER} />
      {withClearFiltersButton && <HeaderClearFiltersButton />}
      {withTableStateChangeSwitch && <HeaderTableStateChangeSwitch />}
      {withRefreshButton && <HeaderRefreshButton />}
      {withCSVDownloadButton && <HeaderExportCSVButton />}
    </StyledHeaderActionPanel>
  )
}

export default HeaderActionPanel
