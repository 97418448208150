import { FC } from 'react'
import { ReportTypeString } from '@/models/reportGenerator/types'
import { Checkbox, Flexbox } from '@skf-internal/ui-components-react'
import { getReportType } from '@/modules/report-generator/utils'
import { dataTestId } from '@/tests/testid'

type ReportCommonCheckboxProps = {
  status: ReportTypeString
  checked: boolean
  onChange: (status: ReportTypeString, checked: boolean) => void
}

const ReportCommonCheckbox: FC<ReportCommonCheckboxProps> = ({ status, checked, onChange }) => {
  return (
    <Flexbox feAlignItems="center" feJustifyContent="space-between">
      <Checkbox
        data-testid={dataTestId.reportGeneratorCommonCheckbox}
        name={status}
        feSize="sm"
        checked={checked}
        feLabel={getReportType(status)}
        onChange={(_, checked) => onChange(status, checked)}
      />
    </Flexbox>
  )
}

export default ReportCommonCheckbox
