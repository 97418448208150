import { SideMenuCard } from '@/modules/devices-sensors/components/styled'
import ChannelDataSection from '@/modules/devicesSensors/components/sideMenu/channelSection'
import DeviceDataSection from '@/modules/devicesSensors/components/sideMenu/deviceSection'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const DevicesSensorsSideMenu: FC = () => {
  return (
    <SideMenuCard>
      <Flexbox data-testid={dataTestId.devicesSensorsSideMenu} feFlexDirection="column" feStretch={true}>
        <DeviceDataSection />
        <ChannelDataSection />
      </Flexbox>
    </SideMenuCard>
  )
}
