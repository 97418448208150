import React, { PropsWithChildren } from 'react'
import { StyledTableCellContent } from '@/modules/unresolved-recommendation/styled'
import { dataTestId } from '@/tests/testid'

interface TableCellContentProps {
  textAlignCondition?: string
}

const TableCellContent: React.FC<PropsWithChildren<TableCellContentProps>> = ({
  children,
  textAlignCondition = '',
}) => {
  return (
    <StyledTableCellContent
      textAlignCondition={textAlignCondition}
      data-testid={dataTestId.unresolvedRecommendationsTableCellContent}
    >
      {children}
    </StyledTableCellContent>
  )
}

export default TableCellContent
