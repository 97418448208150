// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import TableHeaderColumn from '@/shared/components/TableComponents/TableHeaderColumn'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import { ColumnCell } from './commonCells/column-cell'
import { ColumnDef } from '@/common/table/base-components/column-def'
import useWindowDimensions from '@/shared/hooks/useWindowDimensions'
import { sortingRegistry } from '@/shared/components/TableComponents/sortingFunction'
import { formatProp } from '@/shared/utils'

// =================================================================================================
// CONSTANTS
// =================================================================================================

const COLUMN_WIDTH_IN_PERCENT = 25.3

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateLastCommunicationColumnDefinition = (
  columnHelper: ColumnHelper<DevicesSensorsTableDeviceRowData>
) =>
  columnHelper.accessor('lastCommunicationDate', {
    id: 'lastCommunicationDate',
    header: 'Last Communication',
    sortingFn: sortingRegistry.date<DevicesSensorsTableDeviceRowData>,
    enableColumnFilter: true,
    filterFn: filterRegistry.date.filterFn,
    cell: ({ getValue }) => {
      const dateValue = getValue()
      dateValue ? formatDate(dateValue, DateFormats.AmericanDateTimeFormat) : '-'
    },
    meta: {
      filterType: 'date',
    },
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const DevicesSensorsTableLastCommunicationDateColumnDef: FC = () => (
  <ColumnDef columnWidth={COLUMN_WIDTH_IN_PERCENT} />
)

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const DevicesSensorsTableLastCommunicationDateHeaderColumn: FC<{
  header: Header<DevicesSensorsTableDeviceRowData, unknown>
}> = ({ header }) => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 7
    } else if (width < 1440) {
      result = 16
    } else if (width >= 1440) {
      result = 18
    } else {
      result = 17
    }
    return result
  }, [width])

  return (
    <TableHeaderColumn<DevicesSensorsTableDeviceRowData>
      columnWidth={COLUMN_WIDTH_IN_PERCENT}
      header={header}
      centerValue={false}
      characterLength={characterLength}
      title="Last Communication"
    />
  )
}

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

export const DevicesSensorsTableLastCommunicationDateColumnCell: FC<{ value?: string }> = ({ value }) => {
  const { width } = useWindowDimensions()

  const characterLength = useMemo(() => {
    let result = 6
    if (width <= 1200) {
      result = 14
    } else if (width < 1440) {
      result = 16
    } else if (width >= 1440) {
      result = 24
    } else {
      result = 25
    }
    return result
  }, [width])

  return (
    <ColumnCell
      columnWidth={COLUMN_WIDTH_IN_PERCENT}
      value={formatProp<string>(value, (prop) => formatDate(prop, DateFormats.AmericanDateTimeFormat))}
      centerValue={false}
      characterLength={characterLength}
    />
  )
}
